import React from 'react'
import { CCard, CCardBody, CForm, CFormGroup } from '@coreui/react'
import { useTranslation } from 'react-i18next'
import { Input } from 'components/Input'
import { DropDown } from 'components/DropDown'
import { ReactSelect } from 'components/ ReactSelect'
import { Controller } from 'react-hook-form'
import i18next from 'libs/i18n'
import { InputDatePicker } from 'components/InputDatePicker'
import { CheckBox } from 'components/CheckBox'

const activeValues = [
  { value: i18next.t('active') },
  { value: i18next.t('inactive') }
]

const progressiveValues = [
  { value: '1' },
  { value: '2' },
  { value: '3' },
  { value: '4' },
  { value: '5' },
  { value: '6' },
  { value: '7' },
  { value: '8' },
  { value: '9' },
  { value: '10' },
  { value: '11' },
  { value: '12' }
]

const isExtraValues = [{ value: 'false' }, { value: 'true' }]

const RolloutForm = React.memo(props => {
  const { form, withDefaultValue, withActiveStatus } = props

  const { t } = useTranslation()
  return (
    <CCard>
      <CCardBody>
        <CForm className='form-horizontal'>
          <CFormGroup row>
            <Controller
              name='name'
              control={form.control}
              defaultValue=''
              render={({ onChange, value }) => (
                <Input
                  id={t('name')}
                  helperText={t('Please enter name')}
                  placeholder={t('Enter name')}
                  value={value}
                  onChange={onChange}
                  error={form.errors.name?.message}
                />
              )}
            />
          </CFormGroup>

          <CFormGroup row>
            <Controller
              name='year'
              control={form.control}
              defaultValue=''
              render={({ onChange, value }) => (
                <Input
                  id={t('year')}
                  helperText={t('Please enter the year')}
                  placeholder={t('Enter year')}
                  value={value}
                  onChange={onChange}
                  error={form.errors.year?.message}
                />
              )}
            />
          </CFormGroup>

          <CFormGroup row>
            <Controller
              name='progressive'
              control={form.control}
              defaultValue={1}
              render={({ onChange, value }) => (
                <DropDown
                  value={value}
                  onChange={onChange}
                  id={t('progressive')}
                  options={progressiveValues}
                />
              )}
            />
          </CFormGroup>

          <CFormGroup row>
            <Controller
              name='isExtra'
              control={form.control}
              defaultValue={false}
              render={({ onChange, value }) => (
                <DropDown
                  value={value}
                  onChange={onChange}
                  id={t('isExtra')}
                  options={isExtraValues}
                />
              )}
            />
          </CFormGroup>

          <CFormGroup row>
            <Controller
              name='startDate'
              control={form.control}
              defaultValue=''
              render={({ onChange, value }) => (
                <InputDatePicker
                  value={value}
                  onChange={onChange}
                  id={t('startDate')}
                  helperText={t('Please enter enter date')}
                  error={form.errors.startDate?.message}
                />
              )}
            />
          </CFormGroup>

          <CFormGroup row>
            <Controller
              name='endDate'
              control={form.control}
              defaultValue=''
              render={({ onChange, value }) => (
                <InputDatePicker
                  value={value}
                  onChange={onChange}
                  id={t('endDate')}
                  helperText={t('Please enter enter date')}
                  error={form.errors.endDate?.message}
                />
              )}
            />
          </CFormGroup>

          {withActiveStatus && (
            <CFormGroup row>
              <Controller
                name='isCurrent'
                control={form.control}
                defaultValue={withDefaultValue ? t('active') : ''}
                render={({ onChange, value }) => (
                  <DropDown
                    value={value}
                    onChange={onChange}
                    id={t('Status')}
                    options={activeValues}
                  />
                )}
              />
            </CFormGroup>
          )}
        </CForm>
      </CCardBody>
    </CCard>
  )
})

export { RolloutForm }
