import React, { useEffect, useState } from "react";
import { CRow } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { UploadAgentObjectiveCSV } from "api/mutations";
import { Modal } from "components/Modal";
import { Button } from "components/Button";
import { AgentObjectivesAll, AllAgentObjectivesByAgentId } from "api/queries";
import { AllPublishers } from "api/mobileQueries";
import { RenderList } from "components/RenderList";
import { Spiner } from "components/Spiner";
import CIcon from "@coreui/icons-react";
import { UploadInput } from "components/UploadInput";
import { CSVLink } from "react-csv";
import {
  MultilineSearch,
  useMultilineSearch,
} from "../../hooks/useMultilineSearch";

const AgentObjectivesPage = React.memo((props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const limitPerPage = 50;
  // const rolloutsId = history.location.state.rolloutId
  const agent =
    props.user.role === "agent" || props.user.role === "key_account";
  const currentUserId = props.user._id;

  const [pageCount, setPageCount] = useState(1);
  const [filterQueries, setFilterQueries] = useState(
    agent ? [`&agentId=${currentUserId}`] : []
  );
  const [visibleUploadModal, setVisibleUploadModal] = useState(false);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState();
  const [showError, setShowError] = useState(false);
  const setActivePage = (page) => setPageCount(page);

  const uploadCSV = useMutation(UploadAgentObjectiveCSV);
  const queryFunc = () =>
    AgentObjectivesAll(pageCount, limitPerPage, filterQueries);

  /**
   * Query with pagination @staleTime: period in save data
   */
  const agentObjectivesList = useQuery(
    ["AgentObjectives", pageCount || 1, limitPerPage, filterQueries],
    queryFunc,
    {
      retry: false,
      keepPreviousData: true,
      staleTime: 60000,
    }
  );

  const agentObjectiveToCSV = useQuery(
    ["AllAgentObjectives", currentUserId],
    () => AllAgentObjectivesByAgentId(currentUserId),
    {
      retry: false,
    }
  );

  const allPublishers = useQuery(["AllPublishers"], AllPublishers, {
    retry: false,
    keepPreviousData: true,
    staleTime: 60000,
  });

  const refreshQuery = () =>
    queryClient.removeQueries("AgentObjectives", { inactive: true });

  useEffect(() => {
    return refreshQuery;
  }, []);

  const handleVisibleUploadModal = () => {
    setShowError(false);
    setFile(null);
    setFileName("");
    setVisibleUploadModal(!visibleUploadModal);
  };

  const handleCloseUploadModal = () => {
    const close = new Promise((resolve) => {
      resolve(setVisibleUploadModal(false));
    });
    close.then(() =>
      setTimeout(() => {
        uploadCSV.reset();
        queryClient.resetQueries("AgentObjectives");
      }, 500)
    );
  };

  const uploadSubmit = async () => {
    if (file) {
      const form = new window.FormData();
      form.set("file", file);
      await uploadCSV.mutateAsync(form);
    } else {
      setShowError(true);
    }
  };

  const handleUpload = (e) => {
    const name = e.target.files[0].name.split(".")[0];
    setFileName(name);
    setShowError(false);
    setFile(e.target.files[0]);
  };

  const header = [
    "surname",
    "agentCode",
    "publisherCompanyName",
    "publisherCode",
    { label: t("absorptionIndex"), key: "objective" },
  ];
  const searchFieldNames = ["surname", "agentCode", "publisherCompanyName"];
  if (agent) {
    header.splice(0, 2);
    searchFieldNames.splice(0, 2);
  }

  const searchCustomFields = [
    {
      id: "publisherCompanyName",
      type: "select",
      options: allPublishers.data
        ? allPublishers.data.data.map(({ value }) => ({ label: value, value }))
        : [],
    },
  ];
  const { searchProps } = useMultilineSearch(
    searchFieldNames,
    setFilterQueries,
    searchCustomFields
  );

  const arrayCSV = [];
  if (agentObjectiveToCSV.isSuccess) {
    const arr = agentObjectiveToCSV.data.data;
    arrayCSV.push(header.map((el) => el.label));

    arr.forEach((item) => {
      const dataToCSV = [
        item.publisherCompanyName,
        item.publisherCode,
        item.objective,
      ];
      arrayCSV.push(dataToCSV);
    });
  }

  return (
    <>
      <Modal
        visible={visibleUploadModal}
        setVisible={handleCloseUploadModal}
        title={uploadCSV.isSuccess ? t("success") : t("Upload file")}
        type={uploadCSV.isSuccess ? "success" : "primary"}
        fetching={uploadCSV.isLoading}
        body={
          uploadCSV.isSuccess ? (
            t("Agent objectives data changed successfully")
          ) : (
            <UploadInput
              onChange={handleUpload}
              fileName={fileName}
              error={showError}
              errorText={t("Please choose file")}
            />
          )
        }
        toDoBtn={uploadCSV.isSuccess ? t("Close") : t("Submit")}
        toDoFunc={!uploadCSV.isSuccess ? uploadSubmit : handleCloseUploadModal}
        cancelBtn={t("cancel")}
      />
      <CRow className="justify-content-end mt-3 pr-4 pl-4">
        <MultilineSearch
          className="col-sm-10 col-md-10 col-lg-10"
          {...searchProps}
        />
        {agent ? (
          <Button wrapped size="md" color="primary">
            <CSVLink data={arrayCSV}>
              <span style={{ color: "white" }}>{t("Export")}</span>
            </CSVLink>
          </Button>
        ) : (
          <Button
            variant="outline"
            wrapped
            size="md"
            color="primary"
            title={t("Upload")}
            onClick={handleVisibleUploadModal}
            icon={<CIcon name="cil-cloud-upload" />}
          />
        )}
      </CRow>
      <CRow className="justify-content-center pr-4 pl-4">
        {agentObjectivesList.isFetching ? (
          <Spiner />
        ) : (
          agentObjectivesList.isSuccess && (
            <RenderList
              header={t("Agent objectives")}
              data={agentObjectivesList.data.data.results}
              fields={header}
              currentPage={pageCount}
              limit={agentObjectivesList.data.data.limit}
              setActivePage={setActivePage}
              totalPages={agentObjectivesList.data.data.totalPages}
              scopedSlots={{
                objective: (item) => <td>{`${item.objective} %`}</td>,
              }}
            />
          )
        )}
      </CRow>
    </>
  );
});

export { AgentObjectivesPage };
