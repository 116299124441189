import React, { useEffect, useState } from 'react';
import { CRow } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AgentPerfomanceCreate } from 'api/mutations';
import { Modal } from 'components/Modal';
import { Button } from 'components/Button';
import {
  AgentObjectivesByAgentIdVsPublisherCode,
  AgentPerfomanceGetOneByIds,
  RolloutsBooks,
  RolloutsBooksByRolloutId,
} from 'api/queries';
import { RenderList } from 'components/RenderList';
import { Spiner } from 'components/Spiner';
import { useHistory } from 'react-router';
import { Input } from 'components/Input';
import { PDFModal } from 'components/PDFModal';
import {
  MultilineSearch,
  useMultilineSearch,
} from '../../hooks/useMultilineSearch';
import { RolloutBookPreview, AllPublishers } from 'api/mobileQueries';

const AgentsPerfomancePage = React.memo((props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const history = useHistory();
  const limitPerPage = 50;
  const historyState = history.location.state;
  const rolloutId = historyState.rolloutId;
  const agentId = historyState.agentId;
  const agentCode = historyState.agentCode;
  const agentArea = historyState.agentArea;
  const booksellerId = historyState.booksellerId;
  const booksellerCompanyName = historyState.booksellerCompanyName;
  const booksellerCompanyCode = historyState.booksellerCode;
  const rolloutDate = historyState.rolloutDate;

  const [pageCount, setPageCount] = useState(1);
  const [list, setList] = useState(null);
  const [visibleSuccessModal, setVisibleSuccessModal] = useState(false);
  const [filterQueries, setFilterQueries] = useState([]);

  const [visiblePDFModal, setVisiblePDFModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfTitle, setPdfTitle] = useState(null);

  const setActivePage = (page) => setPageCount(page);
  const handleVisibleSuccesModal = () => {
    setVisibleSuccessModal(!visibleSuccessModal);
  };

  // const queryFunc = () =>
  //   RolloutsBooks(pageCount || 1, limitPerPage, filterQueries);
  const queryFunc = () =>
    RolloutsBooksByRolloutId(
      rolloutId,
      pageCount || 1,
      limitPerPage,
      filterQueries,
    );
  const getOneAgentPerfomanceByIds = async (rolloutBookId) => {
    const queryParams = {
      rolloutId,
      agentId,
      booksellerId,
      rolloutBookId,
    };
    const res = await AgentPerfomanceGetOneByIds(queryParams);
    return res;
  };

  const handleRolloutBookPreview = async (rolloutBookId, title) => {
    const res = await RolloutBookPreview(rolloutBookId);
    // const file = new Blob(
    //   [res.data],
    //   { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(res.data);
    setVisiblePDFModal(true);
    setPdfUrl(fileURL);
    setPdfTitle(title);
  };

  /**
   * Query with pagination @staleTime: period in save data
   */
  const rolloutsBooksList = useQuery(
    ['RolloutsBooksAll', pageCount || 1, limitPerPage, filterQueries],
    queryFunc,
    {
      retry: false,
      keepPreviousData: true,
      staleTime: 60000,
    },
  );

  const allPublishers = useQuery(['AllPublishers'], AllPublishers, {
    retry: false,
    keepPreviousData: true,
    staleTime: 60000,
  });

  const createAgentPerfomance = useMutation(AgentPerfomanceCreate);
  const refreshQuery = () =>
    queryClient.removeQueries('RolloutsBooksAll', { inactive: true });
  const handleCreateAgentPerfomance = async (inputsValue) => {
    const payload = {
      rolloutId,
      agentId,
      agentCode,
      agentArea,
      booksellerId,
      booksellerCompanyCode,
      booksellerCompanyName,
      ...inputsValue,
    };
    const res = await createAgentPerfomance.mutateAsync(payload);
    if (res.status === 200) {
      handleVisibleSuccesModal();
    }
  };

  useEffect(() => {
    return refreshQuery;
  }, []);

  const header = [
    'ean',
    'title',
    'author',
    'publisherCompanyName',
    'price',
    'format',
    { key: 'objective', label: t('National objective') },
    'personalObjective',
    'reservedCopies',
    'overDiscount',
    'extensionOfPayment',
    { key: 'preview', label: t('Preview') },
    { key: 'Update', label: t('Update') },
  ];
  const searchFieldNames = [
    'ean',
    'title',
    'author',
    'publisherCompanyName',
    'price',
    'format',
  ];

  const searchCustomFields = [
    {
      id: 'publisherCompanyName',
      type: 'select',
      options: allPublishers.data
        ? allPublishers.data.data.map(({ value }) => ({ label: value, value }))
        : [],
    },
  ];

  const { searchProps } = useMultilineSearch(
    searchFieldNames,
    setFilterQueries,
    searchCustomFields,
  );
  /**
   * For call every row
   * */
  const updateList = async () => {
    const arrayOfList = rolloutsBooksList.data.data.results;
    let bookselleDiscount = 0;
    let booksellerExtensionOfPayment = 0;
    const booksellerId = props.location.state.booksellerId;
    if (
      props.user.discounts.booksellerDiscount.some(
        (el) => el.booksellerId == booksellerId,
      )
    ) {
      const value = props.user.discounts.booksellerDiscount.filter(
        (el) => el.booksellerId == booksellerId,
      );
      bookselleDiscount = value[0].discount;
      booksellerExtensionOfPayment = value[0].extensionOfPayment;
    }

    const newArr = await Promise.all(
      arrayOfList.map(async (item) => {
        let newItem = {
          ...item,
          reservedCopies: '',
          overDiscount: '',
          extensionOfPayment: '',
        };
        try {
          const agentPerformanceResponse = await getOneAgentPerfomanceByIds(
            item._id,
          );
          const agentPerformanceResponseData = agentPerformanceResponse.data;
          if (agentPerformanceResponseData) {
            const { reservedCopies, overDiscount, extensionOfPayment } =
              agentPerformanceResponseData;
            newItem = {
              ...newItem,
              reservedCopies,
              overDiscount,
              extensionOfPayment,
            };
          }
        } catch {}

        try {
          const agentObjectivesResponse =
            await AgentObjectivesByAgentIdVsPublisherCode(
              agentId,
              item.publisherCode,
            );
          const agentObjectivesResponseData = agentObjectivesResponse.data;
          if (agentObjectivesResponseData) {
            const { objective: agentObjectives } = agentObjectivesResponseData;
            newItem = { ...newItem, agentObjectives };
          }
        } catch {}

        const personalObjective =
          Math.floor((newItem.objective / 100) * newItem.agentObjectives) ||
          '-';
        if (!newItem.overDiscount) {
          newItem.overDiscount = bookselleDiscount;
        }
        if (!newItem.extensionOfPayment) {
          newItem.extensionOfPayment = booksellerExtensionOfPayment;
        }
        return { ...newItem, personalObjective };
      }),
    );
    setList(newArr);
  };

  useEffect(() => {
    if (rolloutsBooksList.isSuccess) {
      updateList();
    }
  }, [rolloutsBooksList.dataUpdatedAt]);

  const setValueOfList = (id, field, value) => {
    const newArr = list.map((item) => {
      if (id === item._id) {
        return {
          ...item,
          [field]: value,
        };
      }
      return item;
    });
    setList(newArr);
  };

  return (
    <>
      <PDFModal
        visible={visiblePDFModal}
        setVisible={setVisiblePDFModal}
        url={pdfUrl}
        title={pdfTitle}
      />
      <CRow className="justify-content-center mt-3 pr-4 pl-4">
        <h3>{`${t('Rollout')}: ${rolloutDate}`}</h3>
      </CRow>
      <CRow className="justify-content-center pr-4 pl-4">
        <h3 className="mr-3">
          {`${t('Booksellers company name')}: ${booksellerCompanyName}`}
        </h3>
        <h3>{`${t('Booksellers code')}: ${booksellerCompanyCode}`}</h3>
      </CRow>
      <CRow>
        <MultilineSearch
          className="pl-4 pr-4 col-sm-12 col-md-12 col-lg-12"
          {...searchProps}
        />
      </CRow>
      <Modal
        visible={visibleSuccessModal}
        setVisible={handleVisibleSuccesModal}
        title={t('success')}
        type="success"
        body={t('Agent perfomance created successfully')}
        toDoBtn={t('Close')}
        toDoFunc={handleVisibleSuccesModal}
        cancelBtn={t('cancel')}
      />
      <CRow className="justify-content-center pr-4 pl-4">
        {rolloutsBooksList.isFetching ? (
          <Spiner />
        ) : (
          rolloutsBooksList.isSuccess && (
            <RenderList
              header={t('Agent perfomance')}
              data={list}
              fields={header}
              currentPage={pageCount}
              limit={rolloutsBooksList.data.data.limit}
              setActivePage={setActivePage}
              totalPages={rolloutsBooksList.data.data.totalPages}
              scopedSlots={{
                objective: (item) => <td>{item.objective}</td>,
                reservedCopies: (item) => (
                  <td>
                    <Input
                      withOutLabel
                      id={item._id}
                      min={0}
                      max={1000000}
                      type="number"
                      value={item.reservedCopies}
                      onChange={(e) =>
                        setValueOfList(
                          item._id,
                          'reservedCopies',
                          e.target.value,
                        )
                      }
                    />
                  </td>
                ),
                overDiscount: (item) => (
                  <td>
                    <Input
                      withOutLabel
                      id={item._id}
                      min={0}
                      max={100}
                      type="number"
                      value={item.overDiscount}
                      onChange={(e) =>
                        setValueOfList(item._id, 'overDiscount', e.target.value)
                      }
                    />
                  </td>
                ),
                extensionOfPayment: (item) => (
                  <td>
                    <Input
                      withOutLabel
                      id={item._id}
                      min={0}
                      max={1000000}
                      type="number"
                      value={item.extensionOfPayment}
                      onChange={(e) =>
                        setValueOfList(
                          item._id,
                          'extensionOfPayment',
                          e.target.value,
                        )
                      }
                    />
                  </td>
                ),
                preview: (item) => (
                  <td>
                    <Button
                      variant="outline"
                      color="primary"
                      size="sm"
                      title={t('Preview')}
                      onClick={() =>
                        handleRolloutBookPreview(item._id, item.title)
                      }
                    />
                  </td>
                ),
                Update: (item) => (
                  <td>
                    <Button
                      variant="outline"
                      color="primary"
                      size="sm"
                      title={t('Update')}
                      onClick={() =>
                        handleCreateAgentPerfomance({
                          rolloutBookId: item._id,
                          publisherId: item.publisherId,
                          bookEAN: item.ean,
                          reservedCopies: +item.reservedCopies || 0,
                          overDiscount: +item.overDiscount || 0,
                          extensionOfPayment: +item.extensionOfPayment || 0,
                        })
                      }
                      // icon={<CIcon name='cil-arrow-circle-right' />}
                    />
                  </td>
                ),
              }}
            />
          )
        )}
      </CRow>
    </>
  );
});

export { AgentsPerfomancePage };
