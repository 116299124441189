import React from 'react'
import {CCol, CFormText, CLabel} from "@coreui/react";
import {capitalizeFirstLetter} from "../libs/utils";

const Select = ({
                  value,
                  onChange,
                  options,
                  error,
                  id,
                  helperText,
                  withOutLabel,
                  inlineLabel,
                  inlineLabelClassName,
                  containerClassName
                }) => (
  <div className={containerClassName}>  {(!withOutLabel && !inlineLabel) &&
  <CCol md='3'>
    <CLabel htmlFor={id}>{capitalizeFirstLetter(id)}</CLabel>
  </CCol>}

    <CCol>
      <div className=' d-flex align-items-center'>
        {
          inlineLabel && <CLabel className={`m-0 col-form-label  text-truncate ${inlineLabelClassName}`}
                                 htmlFor={id}>{capitalizeFirstLetter(id)}</CLabel>
        }
        <select onChange={onChange} className='form-control' value={value}>
          <option></option>
          {options.map(option => (
            <option value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>
      <CFormText className='help-block' color={error && 'danger'}>
        {error || helperText}
      </CFormText>
    </CCol>
  </div>
)

export default Select
