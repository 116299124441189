import CIcon from "@coreui/icons-react";
import { CRow } from "@coreui/react";
import { AllPublishers, RolloutBookPreview } from "api/mobileQueries";
import { UploadRolloutsBookCSV } from "api/mutations";
import { RolloutsBooksByRolloutId } from "api/queries";
import { Button } from "components/Button";
import { Modal } from "components/Modal";
import { PDFModal } from "components/PDFModal";
import { RenderList } from "components/RenderList";
import { Spiner } from "components/Spiner";
import { UploadInput } from "components/UploadInput";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import {
  MultilineSearch,
  useMultilineSearch,
} from "../../hooks/useMultilineSearch";

const RolloutBooksPage = React.memo((props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const history = useHistory();
  const limitPerPage = 50;
  const historyState = history.location.state;
  const rolloutsId = historyState.rolloutId;
  const rolloutDate = historyState.rolloutDate;
  const agent =
    props.user.role === "agent" || props.user.role === "key_account";

  const [pageCount, setPageCount] = useState(1);
  /*   const [visibleCreateModal, setVisibleCreateModal] = useState(false)
  const [visibleEditModal, setVisibleEditModal] = useState(false) */
  const [filterQueries, setFilterQueries] = useState([]);
  const [visibleUploadModal, setVisibleUploadModal] = useState(false);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState();
  const [showError, setShowError] = useState(false);
  const setActivePage = (page) => setPageCount(page);

  const [visiblePDFModal, setVisiblePDFModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfTitle, setPdfTitle] = useState(null);

  const uploadCSV = useMutation(UploadRolloutsBookCSV);
  const queryFunc = () =>
    RolloutsBooksByRolloutId(
      rolloutsId,
      pageCount || 1,
      limitPerPage,
      filterQueries
    );
  /**
   * Query with pagination @staleTime: period in save data
   */
  const rolloutsBookList = useQuery(
    ["RolloutsBooks", pageCount, limitPerPage, filterQueries],
    queryFunc,
    {
      retry: false,
      keepPreviousData: true,
      staleTime: 60000,
    }
  );

  const allPublishers = useQuery(["AllPublishers"], AllPublishers, {
    retry: false,
    keepPreviousData: true,
    staleTime: 60000,
  });

  const refreshQuery = () =>
    queryClient.removeQueries("RolloutsBooks", { inactive: true });

  useEffect(() => {
    return refreshQuery;
  }, []);

  const handleVisibleUploadModal = () => {
    setShowError(false);
    setFile(null);
    setFileName("");
    setVisibleUploadModal(!visibleUploadModal);
  };

  const handleCloseUploadModal = () => {
    const close = new Promise((resolve) => {
      resolve(setVisibleUploadModal(false));
    });
    close.then(() =>
      setTimeout(() => {
        uploadCSV.reset();
        queryClient.resetQueries("RolloutsBooks");
      }, 500)
    );
  };

  const uploadSubmit = async () => {
    if (file) {
      const form = new window.FormData();
      form.set("file", file);
      const data = { form, rolloutsId };
      await uploadCSV.mutateAsync(data);
    } else {
      setShowError(true);
    }
  };

  const handleUpload = (e) => {
    const name = e.target.files[0].name.split(".")[0];
    setFileName(name);
    setShowError(false);
    setFile(e.target.files[0]);
  };

  const handleRolloutBookPreview = async (rolloutBookId, title) => {
    const res = await RolloutBookPreview(rolloutBookId);
    // const file = new Blob(
    //   [res.data],
    //   { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(res.data);
    setVisiblePDFModal(true);
    setPdfUrl(fileURL);
    setPdfTitle(title);
  };

  const header = [
    "ean",
    "title",
    "author",
    "publisherCompanyName",
    "price",
    "format",
    { label: t("National objective"), key: "objective" },
    { label: t("Preview"), key: "preview" },
  ];
  const searchFieldNames = [
    "ean",
    "title",
    "author",
    "publisherCompanyName",
    "price",
    "format",
  ];

  const searchCustomFields = [
    {
      id: "publisherCompanyName",
      type: "select",
      options: allPublishers.data
        ? allPublishers.data.data.map(({ value }) => ({ label: value, value }))
        : [],
    },
  ];
  const { searchProps } = useMultilineSearch(
    searchFieldNames,
    setFilterQueries,
    searchCustomFields
  );

  const multilineSearchClass = agent
    ? "col-sm-12 col-md-12 col-lg-12"
    : "col-sm-10 col-md-10 col-lg-10";

  return (
    <>
      <CRow className="justify-content-center mt-3 pr-4 pl-4">
        <h3>{`${t("Rollout")}: ${rolloutDate}`}</h3>
      </CRow>
      <Modal
        visible={visibleUploadModal}
        setVisible={handleCloseUploadModal}
        title={uploadCSV.isSuccess ? t("success") : t("Upload file")}
        type={uploadCSV.isSuccess ? "success" : "primary"}
        fetching={uploadCSV.isLoading}
        body={
          uploadCSV.isSuccess ? (
            t("Rollouts book data changed successfully")
          ) : (
            <UploadInput
              onChange={handleUpload}
              fileName={fileName}
              error={showError}
              errorText={t("Please choose file")}
            />
          )
        }
        toDoBtn={uploadCSV.isSuccess ? t("Close") : t("Submit")}
        toDoFunc={!uploadCSV.isSuccess ? uploadSubmit : handleCloseUploadModal}
        cancelBtn={t("cancel")}
      />
      <PDFModal
        visible={visiblePDFModal}
        setVisible={setVisiblePDFModal}
        url={pdfUrl}
        title={pdfTitle}
      />

      <CRow className="justify-content-end mt-3 pr-4 pl-4">
        <MultilineSearch className={multilineSearchClass} {...searchProps} />
        {!agent && (
          <Button
            variant="outline"
            wrapped
            size="md"
            color="primary"
            title={t("Upload")}
            onClick={handleVisibleUploadModal}
            icon={<CIcon name="cil-cloud-upload" />}
          />
        )}
      </CRow>
      <CRow className="justify-content-center pr-4 pl-4">
        {rolloutsBookList.isFetching ? (
          <Spiner />
        ) : (
          rolloutsBookList.isSuccess && (
            <RenderList
              header={t("Rollout books")}
              data={rolloutsBookList.data.data.results}
              fields={header}
              currentPage={pageCount}
              limit={rolloutsBookList.data.data.limit}
              setActivePage={setActivePage}
              totalPages={rolloutsBookList.data.data.totalPages}
              scopedSlots={{
                [t("price")]: (item) => <td>{`${item.price} €`}</td>,
                objective: (item) => <td>{`${item.objective}`}</td>,
                preview: (item) => (
                  <td>
                    <Button
                      variant="outline"
                      color="primary"
                      size="sm"
                      title={t("Preview")}
                      onClick={() =>
                        handleRolloutBookPreview(item._id, item.title)
                      }
                    />
                  </td>
                ),
              }}
            />
          )
        )}
      </CRow>
    </>
  );
});

export { RolloutBooksPage };
