import React from "react";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import ApplicationRouter from "./ApplicationRouter";
import "./scss/style.scss";
import { useReactPWAInstall } from "react-pwa-install";
import logo from "./assets/pde-64.png";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
} from "@coreui/react";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();
  console.log("SUPPORTED ->", supported());
  console.log("INSTALLED ->", isInstalled());

  const handleClick = () => {
    pwaInstall({
      title: "PDE Giri",
      logo: logo,
      description: "L'applicazione PDE per gli agenti",
    })
      .then(() => alert("Instructions for install shown"))
      .catch(() => alert("User opted out from installing"));
  };

  return (
    <>
      <CContainer>
        <CRow className="justify-content-center">
          <CCol xs="6">
            {supported() && !isInstalled() && (
              <CButton
                color="primary"
                type="submit"
                className="px-4"
                onClick={handleClick}
              >
                Installa app Giri
              </CButton>
            )}
          </CCol>
        </CRow>
      </CContainer>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <React.Suspense fallback={loading}>
            <ApplicationRouter />
          </React.Suspense>
        </BrowserRouter>
      </QueryClientProvider>
    </>
  );
};

export default App;
