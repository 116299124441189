import * as yup from 'yup'
import i18next from 'libs/i18n'

const filterSchema = yup.object().shape({
  companyName: yup.string().optional(),
  surname: yup.string().optional(),
  code: yup.string().optional(),
  city: yup.string().optional()
})

const createSchema = yup.object().shape({
  companyName: yup.string().required(i18next.t('Company name is a required field')),
  surname: yup.string().optional(),
  code: yup.string().required(i18next.t('Code is a required field')),
  city: yup.string().optional(),
  address: yup.string().optional(),
  country: yup.string().optional(),
  facebook: yup.string().optional(),
  name: yup.string().optional(),
  province: yup.string().optional(),
  telephone: yup.string().optional(),
  website: yup.string().optional(),
  zipCode: yup.string().optional(),
  channel: yup.string().optional(),
  clientGroup: yup.string().optional(),
  vatNumber: yup.string().optional(),
  goodsDestination: yup.string().optional(),
  accountType: yup.string().optional(),
  region: yup.string().optional(),
  geographicArea: yup.string().optional(),
  arianna: yup.string().optional()
})

const editSchema = yup.object().shape({
  companyName: yup.string().optional(),
  surname: yup.string().optional(),
  code: yup.string().optional(),
  city: yup.string().optional(),
  address: yup.string().optional(),
  country: yup.string().optional(),
  facebook: yup.string().optional(),
  name: yup.string().optional(),
  province: yup.string().optional(),
  telephone: yup.string().optional(),
  website: yup.string().optional(),
  zipCode: yup.string().optional(),
  channel: yup.string().optional(),
  clientGroup: yup.string().optional(),
  vatNumber: yup.string().optional(),
  goodsDestination: yup.string().optional(),
  accountType: yup.string().optional(),
  region: yup.string().optional(),
  geographicArea: yup.string().optional(),
  arianna: yup.string().optional()
})

export {
  filterSchema,
  createSchema,
  editSchema
}
