import * as yup from 'yup'
import i18next from 'libs/i18n'

const createSchema = yup.object().shape({
  name: yup.string().required(i18next.t('Name is a required field')),
  startDate: yup.string().required(i18next.t('Start date is a required field')),
  endDate: yup.string().required(i18next.t('End date is a required field'))
})

const editSchema = yup.object().shape({
  name: yup.string().optional(),
  startDate: yup.string().optional(),
  endDate: yup.string().optional(),
  isCurrent: yup.string().optional()
})

export {
  createSchema,
  editSchema
}
