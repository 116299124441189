import React, { useEffect, useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CForm,
  CFormGroup,
  CRow
} from '@coreui/react'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { useMutation } from 'react-query'
import { ChangePassword, UpdateMe } from 'api/mutations'
import { Input } from 'components/Input'
import { DropDown } from 'components/DropDown'
import { Spiner } from 'components/Spiner'
import { Modal } from 'components/Modal'
import { ROUTES } from 'routes'
import i18next from 'libs/i18n'
import { useHistory } from 'react-router'

const schema = yup.object().shape({
  name: yup.string().optional(),
  surname: yup.string().optional(),
  language: yup.string().optional()
})

const passwordSchema = yup.object().shape({
  currentPassword: yup
    .string()
    .required(i18next.t('Current password is a required field')),
  newPassword: yup
    .string()
    .required(i18next.t('New password is a required field')),
  confirmPassword: yup
    .string()
    .required(i18next.t('Confirm password is a required field'))
    .test(
      'password-match',
      i18next.t('Confirm password have to match with new password'),
      function (value) {
        return this.parent.newPassword === value
      }
    )
})

const options = [{ value: 'it' }, { value: 'en' }]

const EditUserPage = React.memo((props) => {
  const { t } = useTranslation()

  const history = useHistory()

  const profileForm = useForm({
    resolver: yupResolver(schema)
  })

  const passwordForm = useForm({
    resolver: yupResolver(passwordSchema)
  })

  const changeProfile = useMutation(UpdateMe)
  const changePassword = useMutation(ChangePassword)

  const [visibleModal, setVisibleModal] = useState(false)

  const handleVisibleModal = () => setVisibleModal(!visibleModal)

  const onSubmit = async (data) => {
    for (const key in data) {
      if (data[key] === '') {
        delete data[key]
      }
    }
    await changeProfile.mutateAsync(data)
  }

  const onSubmitPassword = async (data) => {
    await changePassword.mutateAsync(data)
    passwordForm.reset()
  }

  const goToHome = () => history.push(ROUTES.HOME)

  useEffect(() => {
    if (changeProfile.isSuccess || changePassword.isSuccess) {
      handleVisibleModal()
    }
  }, [changeProfile.isSuccess, changePassword.isSuccess])

  return (
    <>
      <Modal
        visible={visibleModal}
        setVisible={handleVisibleModal}
        title={t('success')}
        type='success'
        body={t('sucessfull updated')}
        toDoBtn={t('go home')}
        toDoFunc={goToHome}
        cancelBtn={t('cancel')}
      />

      <CRow className='justify-content-center mt-4'>
        <CCol xs='12' md='6'>
          <CCard>
            <CCardHeader>{t('Change profile info')}</CCardHeader>
            <CCardBody>
              <CForm className='form-horizontal'>
                <CFormGroup row>
                  <Controller
                    name='name'
                    control={profileForm.control}
                    rules={{ required: false }}
                    defaultValue={props.user.name}
                    render={({ onChange, value }) => (
                      <Input
                        id={t('name')}
                        helperText={t('Please enter your name')}
                        // placeholder='Enter Name...'
                        value={value}
                        onChange={onChange}
                        error={profileForm.errors.name?.message}
                      />
                    )}
                  />
                </CFormGroup>

                <CFormGroup row>
                  <Controller
                    name='surname'
                    control={profileForm.control}
                    rules={{ required: false }}
                    defaultValue={props.user.surname}
                    render={({ onChange, value }) => (
                      <Input
                        id={t('surname')}
                        helperText={t('Please enter your surname')}
                        // placeholder='Enter Surname...'
                        value={value}
                        onChange={onChange}
                        error={profileForm.errors.surname?.message}
                      />
                    )}
                  />
                </CFormGroup>

                <CFormGroup row>
                  <Controller
                    name='language'
                    control={profileForm.control}
                    defaultValue={props.user.language}
                    render={({ onChange, value }) => (
                      <DropDown
                        value={value}
                        onChange={onChange}
                        id={t('language')}
                        options={options}
                      />
                    )}
                  />
                </CFormGroup>
              </CForm>
            </CCardBody>
            <CCardFooter>
              {!changeProfile.isLoading
                ? <CButton
                    type='submit'
                    size='md'
                    color='primary'
                    onClick={profileForm.handleSubmit(onSubmit)}
                  >
                  {t('Submit')}
                  </CButton>
                : <Spiner />}
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
      <CRow className='justify-content-center'>
        <CCol xs='12' md='6'>
          <CCard>
            <CCardHeader>{t('Change password')}</CCardHeader>
            <CCardBody>
              <CForm className='form-horizontal'>
                <CFormGroup row>
                  <Controller
                    name='currentPassword'
                    control={passwordForm.control}
                    defaultValue=''
                    render={({ onChange, value, ref }) => (
                      <Input
                        id={t('Current password')}
                        type='password'
                        helperText={t('Please enter your current password')}
                        value={value}
                        onChange={onChange}
                        error={passwordForm.errors.currentPassword?.message}
                      />
                    )}
                  />
                </CFormGroup>

                <CFormGroup row>
                  <Controller
                    name='newPassword'
                    control={passwordForm.control}
                    defaultValue=''
                    render={({ onChange, value }) => (
                      <Input
                        id={t('new password')}
                        type='password'
                        helperText={t('Please enter your new password')}
                        value={value}
                        onChange={onChange}
                        error={passwordForm.errors.newPassword?.message}
                      />
                    )}
                  />
                </CFormGroup>

                <CFormGroup row>
                  <Controller
                    name='confirmPassword'
                    control={passwordForm.control}
                    defaultValue=''
                    render={({ onChange, value }) => (
                      <Input
                        id={t('confirm password')}
                        type='password'
                        helperText={t('Please confirm your new password')}
                        value={value}
                        onChange={onChange}
                        error={passwordForm.errors.confirmPassword?.message}
                      />
                    )}
                  />
                </CFormGroup>
              </CForm>
            </CCardBody>
            <CCardFooter>
              {!changePassword.isLoading
                ? <CButton
                    type='submit'
                    size='md'
                    color='primary'
                    onClick={passwordForm.handleSubmit(onSubmitPassword)}
                  >
                  {t('Submit')}
                  </CButton>
                : <Spiner />}
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
    </>
  )
})

export { EditUserPage }
