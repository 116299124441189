import React, { useEffect, useState } from "react";
import { CRow } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { Button } from "components/Button";
import {
  AgentBooksellersPublishersByAgentId,
  AgentBooksellersPublishersFilterData,
} from "api/queries";
import { RenderList } from "components/RenderList";
import { Spiner } from "components/Spiner";
import CIcon from "@coreui/icons-react";
import { useHistory } from "react-router";
import { AsyncMultiSelect } from "components/AsyncMultiSelect";
import { ROUTES } from "routes";
import {
  MultilineSearch,
  useMultilineSearch,
} from "../../hooks/useMultilineSearch";

const AgentsBooksellersDetailsPage = React.memo((props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const history = useHistory();
  const limitPerPage = 50;
  const rolloutsId = history.location.state.rolloutId;
  const rolloutDate = history.location.state.rolloutDate;
  const agent =
    props.user.role === "agent" || props.user.role === "key_account";
  const agentId = props.user._id;
  const agentCode = props.user.agentCode;
  const agentArea = props.user.area;
  let distinctValue = "";
  if (agent) {
    distinctValue = "booksellerId";
  }
  const [pageCount, setPageCount] = useState(1);
  const [filterQueries, setFilterQueries] = useState([]);
  const setActivePage = (page) => setPageCount(page);

  const searchFieldNames = [
    "booksellerCompanyName",
    "booksellerCity",
    "booksellerCode",
  ];
  const { searchProps } = useMultilineSearch(
    searchFieldNames,
    setFilterQueries
  );

  const queryFunc = () =>
    AgentBooksellersPublishersByAgentId(
      agentId,
      distinctValue,
      pageCount || 1,
      limitPerPage,
      filterQueries
    );

  /**
   * Query with pagination @staleTime: period in save data
   */
  const agentBooksellersPublishersList = useQuery(
    [
      "AgentBooksellersDetails",
      agentId,
      distinctValue,
      pageCount,
      limitPerPage,
      filterQueries,
    ],
    queryFunc,
    {
      retry: false,
      keepPreviousData: true,
      staleTime: 60000,
    }
  );

  const refreshQuery = () =>
    queryClient.removeQueries("AgentBooksellersDetails", { inactive: true });

  useEffect(() => {
    return refreshQuery;
  }, []);

  const header = [
    "booksellerCompanyName",
    "booksellerCity",
    "booksellerCode",
    { key: "Handle bookseller", label: t("Handle bookseller") },
  ];

  return (
    <>
      <CRow className=" mt-3 pr-4 pl-4">
        <MultilineSearch
          className="col-sm-10 col-md-10 col-lg-10"
          {...searchProps}
        />
      </CRow>
      <CRow className="justify-content-center pr-4 pl-4">
        {agentBooksellersPublishersList.isFetching ? (
          <Spiner />
        ) : (
          agentBooksellersPublishersList.isSuccess && (
            <RenderList
              header={t("Booksellers")}
              data={agentBooksellersPublishersList.data.data.results}
              fields={header}
              currentPage={pageCount}
              limit={agentBooksellersPublishersList.data.data.limit}
              setActivePage={setActivePage}
              totalPages={agentBooksellersPublishersList.data.data.totalPages}
              scopedSlots={{
                [t("Agent name")]: (item) => <td>{item.agentId.name}</td>,
                [t("Agent surname")]: (item) => <td>{item.agentId.surname}</td>,
                "Handle bookseller": (item) => (
                  <td>
                    <Button
                      variant="outline"
                      color="primary"
                      size="sm"
                      title={t("Handle bookseller")}
                      onClick={() =>
                        history.push(ROUTES.AGENTS_PERFOMANCE, {
                          booksellerId: item.booksellerId,
                          rolloutId: rolloutsId,
                          agentId,
                          agentCode,
                          agentArea,
                          booksellerCompanyName: item.booksellerCompanyName,
                          booksellerCode: item.booksellerCode,
                          rolloutDate,
                        })
                      }
                      icon={<CIcon name="cil-arrow-circle-right" />}
                    />
                  </td>
                ),
              }}
            />
          )
        )}
      </CRow>
    </>
  );
});

export { AgentsBooksellersDetailsPage };
