import React, { useState } from "react";
import Expand from "react-expand-animated";
import { Button } from "components/Button";
import { Input } from "../components/Input";
import { useTranslation } from "react-i18next";
import { getFromArrayByKey } from "../libs/utils";
import Select from "../components/Select";

export const MultilineSearch = ({
  className,
  searchFields,
  handleSubmit,
  handleDrop,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();

  const handleExpandClick = () => {
    setIsOpen(!isOpen);
  };
  const handleSearchClick = () => {
    handleSubmit();
    setIsOpen(false);
  };

  const isDirty = searchFields.some((field) => field.value);
  const handleDropFilters = () => {
    handleDrop();
    setIsOpen(false);
  };

  return (
    <div className={`p-0 mb-1 mb-md-0 ${className}`}>
      <div className="container-fluid p-0 d-flex">
        <Button
          color={isOpen ? "" : "primary"}
          size="md"
          wrapped
          onClick={handleExpandClick}
        >
          {t("Filter")}
        </Button>
        {isDirty && (
          <Button
            size="md"
            wrapped
            className="w-auto text-nowrap"
            onClick={handleDropFilters}
          >
            {t("Drop Filters")}
          </Button>
        )}
      </div>
      <Expand open={isOpen}>
        <div className="p-2 container-fluid">
          {searchFields.map((field) => {
            const commonProps = {
              value: field.value,
              id: t(field.id),
              key: field.id,
              onChange: (e) => field.handleChange(e.target.value),
              inlineLabel: true,
              inlineLabelClassName: "col-sm-2 col-md-2 col-lg-2 pl-0",
            };
            if (field.type === "text") {
              return <Input {...commonProps} />;
            } else if (field.type === "select") {
              return <Select {...{ ...commonProps }} options={field.options} />;
            } else {
              return <Input {...commonProps} />;
            }
          })}
        </div>
        <Button
          color="primary"
          wrapped
          onClick={handleSearchClick}
          disabled={!isDirty}
        >
          {t("Search")}
        </Button>
      </Expand>
    </div>
  );
};

export const useMultilineSearch = (
  searchFieldNames,
  onSearch,
  customFields
) => {
  const searchFilterInit = Object.fromEntries(
    searchFieldNames.map((field) => [field, ""])
  );

  const getQuery = (filters) =>
    Object.entries(filters)
      .filter(([_, v]) => !!v)
      .map(([k, v]) => `&${k}=${v}`);

  const [searchFilter, setSearchFilter] = useState(searchFilterInit);

  let searchFields = searchFieldNames.map((field) => ({
    id: field,
    value: searchFilter[field],
    type: "text",
    handleChange: (value) =>
      setSearchFilter((prev) => ({ ...prev, [field]: value })),
  }));

  if (customFields) {
    searchFields = searchFields.map((field) => {
      const customField = getFromArrayByKey(customFields, "id", field.id) || {};
      return { ...field, ...customField };
    });
  }

  const handleSubmitSearchFilter = () => {
    //console.log("--...searchFilter", searchFilter);
    onSearch(Object.assign({}, searchFilter));
  };

  const handleDropSearchFilter = () => {
    setSearchFilter(searchFilterInit);
    onSearch(getQuery(searchFilterInit));
  };

  return {
    searchProps: {
      searchFields: searchFields,
      handleSubmit: handleSubmitSearchFilter,
      handleDrop: handleDropSearchFilter,
    },
  };
};
