import React, { useEffect, useState } from 'react';
import { CBadge, CRow } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { CreateRollout, EditRolloutsById } from 'api/mutations';
import { Modal } from 'components/Modal';
import { RolloutForm } from './assets/RolloutForm';
import { Button } from 'components/Button';
import { RolloutDownload, Rollouts } from 'api/queries';
import { RenderList } from 'components/RenderList';
import { Spiner } from 'components/Spiner';
import { convertDate, filterEmptyKeys } from '../../libs/utils';
import CIcon from '@coreui/icons-react';
import { createSchema, editSchema } from './assets/schemas';
import { useHistory } from 'react-router';
import { ROUTES } from 'routes';
import { CSVLink } from 'react-csv';
import { extractFileName } from 'helpers/responseHelper';

const RolloutsPage = React.memo((props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const history = useHistory();
  const agent =
    props.user.role === 'agent' || props.user.role === 'key_account';
  const limitPerPage = 50;

  const [pageCount, setPageCount] = useState(1);
  const [visibleCreateModal, setVisibleCreateModal] = useState(false);
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [editRolloutsId, setEditRolloutsId] = useState('');

  const setActivePage = (page) => setPageCount(page);

  const createForm = useForm({
    resolver: yupResolver(createSchema),
  });

  const editForm = useForm({
    resolver: yupResolver(editSchema),
  });

  const createRollout = useMutation(CreateRollout);
  const editRollout = useMutation(EditRolloutsById);

  const handleVisibleCreateModal = () => {
    createForm.reset();
    setVisibleCreateModal(!visibleCreateModal);
  };

  const arrayCSV = [
    [
      'ISBN',
      'TITOLO',
      'AUTORE',
      'EDITORE',
      'PREZZO',
      'Tipo Formato',
      'OBIETTIVO',
      'ranking',
      'CODICE EDITORE',
    ],
    [
      '9788845937132',
      'MESENKA',
      'NABOKOV VLADIMIR',
      'ADELPHI EDIZIONI',
      20,
      'cover',
      7000,
      1,
      'A94',
    ],
    [
      '9788845937125',
      'TESSITURE DI SOGNO',
      'SEBALD W G ',
      'ADELPHI EDIZIONI',
      20,
      'cover',
      4000,
      2,
      'A94',
    ],
  ];

  const handleCloseEditModal = () => {
    /* Need it for fixed bug with edit form after success update */
    const close = new Promise((resolve) => {
      resolve(setVisibleEditModal(false));
    });
    close.then(() =>
      setTimeout(() => {
        editRollout.reset();
      }, 300),
    );
  };

  const openEditModal = (item) => {
    console.log(item);
    /* Add current value to form */
    editForm.setValue('name', item.name ? item.name : '');
    editForm.setValue(
      'startDate',
      item.startDate ? new Date(item.startDate) : '',
    );
    editForm.setValue('endDate', item.endDate ? new Date(item.endDate) : '');
    editForm.setValue('isCurrent', item.isCurrent ? 'active' : 'inactive');
    editForm.setValue('year', item.year ? item.year : '');
    editForm.setValue('progressive', item.progressive ? item.progressive : '');
    editForm.setValue(
      'isExtra',
      item.isExtra ? item.isExtra.toString() : 'false',
    );
    setVisibleEditModal(true);
    setEditRolloutsId(item._id);
  };
  const queries = [];
  if (agent) {
    queries.push('&isCurrent=true');
  }

  /**
   * Query with pagination @staleTime: period in save data
   */
  const rolloutsList = useQuery(
    ['Rollouts', pageCount],
    () => Rollouts(pageCount || 1, limitPerPage, queries),
    {
      retry: false,
      keepPreviousData: true,
      staleTime: 60000,
    },
  );

  const mutationDownload = useMutation(RolloutDownload);

  const downloaRollout = async (rolloutId, agentId) => {
    try {
      const response = await mutationDownload.mutateAsync(rolloutId, agentId);
      const filename = extractFileName(response.headers['content-disposition']);
      const blob = response.data;
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.click();
      const file = window.URL.createObjectURL(blob);
      window.location.assign(file);
    } catch (error) {
      console.log(error);
    }
  };

  const download = (url) => {
    // window.location.href = url;
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = url.split('/').pop();
        link.click();
        const file = window.URL.createObjectURL(blob);
        window.location.assign(file);
        //fileDownload(blob, url.split("/").pop());
      })
      .catch(console.error);
  };

  useEffect(() => {
    if (visibleCreateModal) {
      createRollout.reset();
      createForm.setValue('name', '');
      createForm.setValue('startDate', '');
      createForm.setValue('endDate', '');
      createForm.setValue('year', new Date().getFullYear());
      createForm.setValue('progressive', 1);
      createForm.setValue('isExtra', 'false');
    }
  }, [visibleCreateModal]);

  const createSubmit = async (data) => {
    console.log(data);
    await createRollout.mutateAsync(data);
    await queryClient.refetchQueries('Rollouts');
  };

  const editSubmit = async (data) => {
    const filtered = filterEmptyKeys(data);
    filtered.rolloutsId = editRolloutsId;
    console.log(data);
    filtered.isCurrent =
      filtered.isCurrent === 'active' || filtered.isCurrent === 'attivo';

    await editRollout.mutateAsync(filtered);
    await queryClient.refetchQueries('Rollouts');
  };

  const arrOfFields = [
    'name',
    'startDate',
    'endDate',
    { key: 'isExtra', label: t('Extra') },
    { key: 'Status', label: t('Status') },
    { key: 'Edit', label: t('Edit') },
    { key: 'AgentPerfomances', label: t('Agent Perfomances') },
    { key: 'Rolloutbooks', label: t('Rollout books') },
    { key: 'Details', label: t('Details') },
    { key: 'Download', label: 'Scarica prenotati' },
  ];
  if (agent) {
    arrOfFields.splice(4, 3);
  } else {
    arrOfFields.splice(8, 1);
  }

  return (
    <>
      <Modal
        visible={visibleCreateModal}
        setVisible={handleVisibleCreateModal}
        title={createRollout.isSuccess ? t('success') : t('Create rollout')}
        type={createRollout.isSuccess ? 'success' : 'primary'}
        fetching={createRollout.isLoading}
        body={
          createRollout.isSuccess ? (
            t('Rollout created successfully')
          ) : (
            <RolloutForm form={createForm} />
          )
        }
        toDoBtn={createRollout.isSuccess ? t('Close') : t('Submit')}
        toDoFunc={
          !createRollout.isSuccess
            ? createForm.handleSubmit(createSubmit)
            : handleVisibleCreateModal
        }
        cancelBtn={t('cancel')}
      />
      <Modal
        visible={visibleEditModal}
        setVisible={handleCloseEditModal}
        title={editRollout.isSuccess ? t('success') : t('Edit Rollout')}
        type={editRollout.isSuccess ? 'success' : 'primary'}
        fetching={editRollout.isLoading}
        body={
          editRollout.isSuccess ? (
            t('Rollout changed successfully')
          ) : (
            <RolloutForm withActiveStatus form={editForm} />
          )
        }
        toDoBtn={editRollout.isSuccess ? t('Close') : t('Submit')}
        toDoFunc={
          !editRollout.isSuccess
            ? editForm.handleSubmit(editSubmit)
            : handleCloseEditModal
        }
        cancelBtn={t('cancel')}
      />
      <CRow className="justify-content-end mt-3 pr-4 pl-4">
        {!agent && (
          <>
            <Button
              variant="outline"
              wrapped
              size="md"
              color="primary"
              title={t('Create')}
              onClick={handleVisibleCreateModal}
              icon={<CIcon name="cil-playlist-add" />}
            />
            {/* <Button
              variant="outline"
              wrapped
              size="md"
              color="info"
              onClick={() => {
                download("/esempio-tracciato-giro.csv");
              }}
            >
              {"   "}
              <i
                className="fas fa-download  mr-2"
                style={{ color: "#ffffff", pointerEvents: "none" }}
              />
              Tracciato di esempio
            </Button> */}
            <CSVLink
              data={arrayCSV}
              className="col-md-2"
              filename={'tracciato-di-esempio.csv'}
            >
              <Button variant="outline" size="xl" color="info">
                Tracciato di esempio
              </Button>
            </CSVLink>
          </>
        )}
      </CRow>
      <CRow className="justify-content-center pr-4 pl-4">
        {rolloutsList.isFetching ? (
          <Spiner />
        ) : (
          rolloutsList.isSuccess && (
            <RenderList
              header={t('Rollouts')}
              data={rolloutsList.data.data.results}
              fields={arrOfFields}
              currentPage={pageCount}
              limit={rolloutsList.data.data.limit}
              setActivePage={setActivePage}
              totalPages={rolloutsList.data.data.totalPages}
              scopedSlots={{
                startDate: (item) => <td>{convertDate(item.startDate)}</td>,
                endDate: (item) => <td>{convertDate(item.endDate)}</td>,
                Status: (item) => (
                  <td>
                    <CBadge color={item.isCurrent ? 'success' : 'primary'}>
                      {item.isCurrent ? t('Active') : t('Inactive')}
                    </CBadge>
                  </td>
                ),
                Edit: (item) => (
                  <td>
                    <Button
                      variant="outline"
                      color="primary"
                      size="md"
                      title={t('Edit')}
                      onClick={() => openEditModal(item)}
                      icon={<CIcon name="cil-pencil" size="sm" />}
                    />
                  </td>
                ),
                AgentPerfomances: (item) => (
                  <td>
                    <Button
                      variant="outline"
                      color="primary"
                      size="md"
                      title={t('Agent Perfomances')}
                      onClick={() =>
                        history.push(ROUTES.AGENTS_PERFOMANCES_ALL, {
                          rolloutId: item._id,
                          rolloutDate: `${convertDate(
                            item.startDate,
                          )} - ${convertDate(item.endDate)}`,
                        })
                      }
                      icon={<CIcon name="cil-arrow-circle-right" />}
                    />
                  </td>
                ),
                Rolloutbooks: (item) => (
                  <td>
                    <Button
                      variant="outline"
                      color="primary"
                      size="md"
                      title={t('Rollout books')}
                      onClick={() =>
                        history.push(ROUTES.ROLLOUTS_BOOKS, {
                          rolloutId: item._id,
                          rolloutDate: `${convertDate(
                            item.startDate,
                          )} - ${convertDate(item.endDate)}`,
                        })
                      }
                      icon={<CIcon name="cil-arrow-circle-right" />}
                    />
                  </td>
                ),
                'Agent objectives': (item) => (
                  <td>
                    <Button
                      variant="outline"
                      color="primary"
                      size="sm"
                      title={t('Agent objectives')}
                      onClick={() =>
                        history.push(ROUTES.AGENT_OBJECTIVES, {
                          rolloutId: item._id,
                        })
                      }
                      icon={<CIcon name="cil-arrow-circle-right" />}
                    />
                  </td>
                ),
                Details: (item) => (
                  <td>
                    <Button
                      variant="outline"
                      color="primary"
                      size="sm"
                      title={t('Details')}
                      onClick={() =>
                        history.push(ROUTES.AGENTS_BOOKSELLERS_DETAILS, {
                          rolloutId: item._id,
                          rolloutDate: `${convertDate(
                            item.startDate,
                          )} - ${convertDate(item.endDate)}`,
                        })
                      }
                      icon={<CIcon name="cil-arrow-circle-right" />}
                    />
                  </td>
                ),
                Download: (item) => (
                  <td>
                    <Button
                      variant="outline"
                      color="primary"
                      size="sm"
                      title="Scarica Prenotati"
                      onClick={() => {
                        downloaRollout({
                          rolloutId: item._id,
                          agentId: props.user._id,
                          name: item.name,
                        });
                      }} // ._id => rolloutId
                      icon={<CIcon name="cil-arrow-circle-right" />}
                    />
                  </td>
                ),
              }}
            />
          )
        )}
      </CRow>
    </>
  );
});

export { RolloutsPage };
