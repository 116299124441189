import { CCol, CFormText, CLabel } from '@coreui/react'
import { capitalizeFirstLetter } from 'libs/utils'
import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const InputDatePicker = React.memo((props) => {
  const {
    id,
    withOutLabel,
    error,
    helperText,
    value,
    onChange
  } = props

  const today = new Date()

  return (
    <>
      {!withOutLabel &&
        <CCol md='3'>
          <CLabel htmlFor={id}>{capitalizeFirstLetter(id)}</CLabel>
        </CCol>}
      <CCol>
        <DatePicker
          id={id}
          className='form-control'
          dateFormat='dd/MM/yyyy'
          placeholder='Enter date'
          selected={value}
          onChange={onChange}
        />
        <CFormText className='help-block' color={error && 'danger'}>
          {error || helperText}
        </CFormText>
      </CCol>
    </>
  )
})

export { InputDatePicker }
