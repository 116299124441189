import React from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute, OnlyPublicRoute, ROUTES } from 'routes';
import withCurrentUser from 'hoc/withCurrentUser';
import LoginPage from 'views/auth/LoginPage';
import ForgotPasswordPage from 'views/auth/ForgotPasswordPage';
import ResetPasswordPage from 'views/auth/ResetPasswordPage';
import DashboardPage from 'views/dashboard/DashboardPage';

import { TheLayout } from 'containers';
import { EditUserPage } from 'views/profile/EditUserPage';
import { UsersPage } from 'views/users/UsersPage';
import { BookSellersPage } from 'views/booksellers/BookSellersPage';
import ROLES from 'libs/roles';
import { RolloutsPage } from 'views/rollouts/RolloutsPage';
import { RolloutBooksPage } from 'views/rolloutBooks/RolloutBooksPage';
import { AgentObjectivesPage } from 'views/agentObjectives/AgentObjectivesPage';
import { AgentsBooksellersDetailsPage } from 'views/agentsBooksellersDetails/AgentsBooksellersDetailsPage';
import { AgentsPerfomancePage } from 'views/agentsPerfomance/AgentsPerfomancePage';
import { AgentBooksellerPublisherPage } from 'views/agentBooksellerPublisher/AgentBooksellerPublisherPage';
import { AllAgentsPerfomancesPage } from 'views/allAgentsPerfomances/AllAgentsPerfomances';
import { DiscountPage } from 'views/discounts/DiscountPage';

const Private = withCurrentUser(PrivateRoute);
const OnlyPublic = withCurrentUser(OnlyPublicRoute);

const ApplicationRouter = () => {
  return (
    <Switch>
      <OnlyPublic exact path={ROUTES.LOGIN} component={LoginPage} />
      <OnlyPublic
        exact
        path={ROUTES.FORGOT_PASSWORD}
        component={ForgotPasswordPage}
      />
      <OnlyPublic
        exact
        path={ROUTES.RESET_PASSWORD}
        component={ResetPasswordPage}
      />
      <Private
        exact
        path={ROUTES.HOME}
        layout={TheLayout}
        allowedRoles={[
          ROLES.USER.ROLE,
          ROLES.AGENT.ROLE,
          ROLES.ADMIN,
          ROLES.KEY_ACCOUNT,
        ]}
        component={withCurrentUser(DashboardPage)}
      />
      <Private
        exact
        path={ROUTES.PROFILE}
        layout={TheLayout}
        allowedRoles={[
          ROLES.USER.ROLE,
          ROLES.AGENT.ROLE,
          ROLES.ADMIN,
          ROLES.KEY_ACCOUNT,
        ]}
        component={withCurrentUser(EditUserPage)}
      />
      <Private
        exac
        path={ROUTES.USERS}
        layout={TheLayout}
        allowedRoles={[ROLES.ADMIN]}
        component={UsersPage}
      />
      <Private
        exac
        path={ROUTES.BOOKSELLERS}
        layout={TheLayout}
        allowedRoles={[ROLES.ADMIN, ROLES.USER.AGENTS_MANAGEMENT]}
        component={BookSellersPage}
      />
      <Private
        exac
        path={ROUTES.ROLLOUTS}
        layout={TheLayout}
        allowedRoles={[ROLES.ADMIN, ROLES.AGENT.ROLE, ROLES.KEY_ACCOUNT]}
        component={withCurrentUser(RolloutsPage)}
      />
      <Private
        exac
        path={ROUTES.ROLLOUTS_BOOKS}
        layout={TheLayout}
        allowedRoles={[ROLES.ADMIN, ROLES.AGENT.ROLE, ROLES.KEY_ACCOUNT]}
        component={withCurrentUser(RolloutBooksPage)}
      />
      <Private
        exac
        path={ROUTES.AGENT_OBJECTIVES}
        layout={TheLayout}
        allowedRoles={[ROLES.ADMIN, ROLES.AGENT.ROLE, ROLES.KEY_ACCOUNT]}
        component={withCurrentUser(AgentObjectivesPage)}
      />
      <Private
        exac
        path={ROUTES.AGENTS_BOOKSELLERS_DETAILS}
        layout={TheLayout}
        allowedRoles={[ROLES.AGENT.ROLE, ROLES.KEY_ACCOUNT]}
        component={withCurrentUser(AgentsBooksellersDetailsPage)}
      />
      <Private
        exac
        path={ROUTES.AGENTS_PERFOMANCE}
        layout={TheLayout}
        allowedRoles={[ROLES.AGENT.ROLE, ROLES.KEY_ACCOUNT]}
        component={AgentsPerfomancePage}
      />
      <Private
        exac
        path={ROUTES.AGENTS_BOOKSELLERS_PUBLISHERS}
        layout={TheLayout}
        allowedRoles={[ROLES.ADMIN]}
        component={AgentBooksellerPublisherPage}
      />
      <Private
        exac
        path={ROUTES.AGENTS_PERFOMANCES_ALL}
        layout={TheLayout}
        allowedRoles={[ROLES.ADMIN]}
        component={AllAgentsPerfomancesPage}
      />
      <Private
        exac
        path={ROUTES.OVER_DISCOUNTS}
        layout={TheLayout}
        allowedRoles={[ROLES.ADMIN, ROLES.AGENT.ROLE, ROLES.KEY_ACCOUNT]}
        component={withCurrentUser(DiscountPage)}
      />
    </Switch>
  );
};

export default ApplicationRouter;
