import DashboardForAdminRedesign from './DashboardForAdminRedesign'
import DashboardForAgent from './DashboardForAgent'

const Dashboard = (props) => {
  const userRole = props.user.role
  const userId = props.user._id

  if (userRole === 'admin') {
    return <DashboardForAdminRedesign />
  } else if (userRole === 'agent' || userRole  === 'key_account') {
    return <DashboardForAgent agentId={userId} />
  }
}

export default Dashboard
