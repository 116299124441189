import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CSidebar,
  CSidebarClose
} from '@coreui/react'
import {
  CIcon
} from '@coreui/icons-react'
import { Logout } from 'api/mutations'
import { Link } from 'react-router-dom'

const TheAside = () => {
  const show = useSelector(state => state.asideShow)
  const dispatch = useDispatch()
  const setState = (state) => dispatch({ type: 'set', asideShow: state })

  const logout = () => {
    Logout()
    window.location.href = '/'
  }

  return (
    <CSidebar
      aside
      colorScheme='light'
      size='lg'
      overlaid
      show={show}
      onShowChange={(state) => setState(state)}
    >
      <CSidebarClose onClick={() => setState(false)} />
      {/* aside content */}
      <div className='nav-underline'>
        <div className='nav nav-tabs'>
          <div className='nav-item'>
            <div className='nav-link'>
              <Link to='/user/edit' className='nav-link' id='user-edit'>
                <CIcon size='sm' name='cilSettings' />
              </Link>
            </div>

            <a href='#' onClick={logout} className='nav-link' id='logout'><CIcon size='sm' name='cilSettings' /></a>
          </div>
        </div>
      </div>
    </CSidebar>
  )
}

export default React.memo(TheAside)
