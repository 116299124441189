import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'
import React from 'react'
import { Spiner } from './Spiner'

const Modal = React.memo((props) => {
  const {
    visible,
    setVisible,
    title,
    body,
    toDoFunc,
    toDoBtn,
    cancelBtn,
    resetBtn,
    resetFunc,
    type,
    fetching
  } = props

  return (
    <CModal
      show={visible}
      onClose={setVisible}
      color={type}
    >
      <CModalHeader closeButton>
        <CModalTitle>{title}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        {body || props.children}
      </CModalBody>
      <CModalFooter>
        {fetching
          ? <Spiner />
          : <CButton color={type} onClick={toDoFunc}>
            {toDoBtn}
          </CButton>}{' '}
        {resetBtn &&
          <CButton color='danger' onClick={resetFunc}>
            {resetBtn}
          </CButton>}
        <CButton color='secondary' onClick={setVisible}>
          {cancelBtn}
        </CButton>
      </CModalFooter>
    </CModal>
  )
})

export { Modal }
