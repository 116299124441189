import React from 'react'
import { CCard, CCardBody, CCardHeader, CCol, CDataTable } from '@coreui/react'
import { Paginator } from './Paginator'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

const RenderList = React.memo((props) => {
  const {
    header,
    data,
    fields,
    limit,
    scopedSlots,
    totalPages,
    currentPage,
    setActivePage
  } = props

  const { t } = useTranslation()

  const fieldsWithTranslation = fields.map(f => {
    if (_.isString(f)) {
      return { key: f, label: t(f) }
    } else {
      return f
    }
  })

  return (
    <CCol xs='12' lg='12' className='mt-2'>
      <CCard className='mb-2'>
        <CCardHeader>
        {header}
      </CCardHeader>
        <CCardBody>
          <CDataTable
            items={data}
            fields={fieldsWithTranslation}
            striped
            itemsPerPage={limit}
            scopedSlots={scopedSlots}
          />
          <Paginator
            totalPages={totalPages}
            currentPage={currentPage}
            setActivePage={setActivePage}
          />
        </CCardBody>
      </CCard>
    </CCol>
  )
})

export { RenderList }
