import React, { useEffect, useState } from "react";
import { CBadge, CRow } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  CreateBookSeller,
  EditBookSellerById,
  UploadBooksellersCSV,
} from "api/mutations";
import { Modal } from "components/Modal";
import { Button } from "components/Button";
import { BookSellers } from "api/queries";
import { RenderList } from "components/RenderList";
import { Spiner } from "components/Spiner";
import { filterEmptyKeys } from "../../libs/utils";
import { createSchema, editSchema } from "./assets/schemas";
import { BookSellerForm } from "./assets/BookSellerForm";

import { UploadInput } from "components/UploadInput";
import {
  MultilineSearch,
  useMultilineSearch,
} from "../../hooks/useMultilineSearch";

const BookSellersPage = React.memo(() => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const limitPerPage = 50;

  const [pageCount, setPageCount] = useState(1);
  const [visibleCreateModal, setVisibleCreateModal] = useState(false);
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [visibleUploadModal, setVisibleUploadModal] = useState(false);
  const [filterQueries, setFilterQueries] = useState([]);
  const [editBookSellersId, setEditBookSellersId] = useState("");
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState();
  const [showError, setShowError] = useState(false);

  const setActivePage = (page) => setPageCount(page);

  const editForm = useForm({
    resolver: yupResolver(editSchema),
  });

  const createForm = useForm({
    resolver: yupResolver(createSchema),
  });

  const handleVisibleCreateModal = () => {
    createForm.reset();
    setVisibleCreateModal(!visibleCreateModal);
  };

  const handleVisibleUploadModal = () => {
    setShowError(false);
    setFile(null);
    setFileName("");
    setVisibleUploadModal(!visibleUploadModal);
  };

  const handleOpenEditModal = (item) => {
    editForm.setValue("name", item.name ? item.name : "");
    editForm.setValue("surname", item.surname ? item.surname : "");
    editForm.setValue("email", item.email ? item.email : "");
    editForm.setValue("companyName", item.companyName ? item.companyName : "");
    editForm.setValue("code", item.code ? item.code : "");
    editForm.setValue("city", item.city ? item.city : "");
    editForm.setValue("province", item.province ? item.province : "");
    editForm.setValue("address", item.address ? item.address : "");
    editForm.setValue("zipCode", item.zipCode ? item.zipCode : "");
    editForm.setValue("country", item.country ? item.country : "");
    editForm.setValue("telephone", item.telephone ? item.telephone : "");
    editForm.setValue("website", item.website ? item.website : "");
    editForm.setValue("facebook", item.facebook ? item.facebook : "");
    editForm.setValue("chanel", item.chanel ? item.chanel : "");
    editForm.setValue("clientGroup", item.clientGroup ? item.clientGroup : "");
    editForm.setValue("vatNumber", item.vatNumber ? item.vatNumber : "");
    editForm.setValue(
      "goodsDestination",
      item.goodsDestination ? item.goodsDestination : ""
    );
    editForm.setValue("accountType", item.accountType ? item.accountType : "");
    editForm.setValue("region", item.region ? item.region : "");
    editForm.setValue(
      "geographicArea",
      item.geographicArea ? item.geographicArea : ""
    );
    editForm.setValue("arianna", item.arianna ? item.arianna : "");
    setVisibleEditModal(true);
    setEditBookSellersId(item._id);
  };

  const handleCloseEditModal = () => {
    /* Need it for fixed bug with edit form after success update */
    const close = new Promise((resolve) => {
      resolve(setVisibleEditModal(false));
    });
    close.then(() =>
      setTimeout(() => {
        editBookSellers.reset();
      }, 300)
    );
  };

  const handleCloseUploadModal = () => {
    const close = new Promise((resolve) => {
      resolve(setVisibleUploadModal(false));
    });
    close.then(() =>
      setTimeout(() => {
        uploadCSV.reset();
        queryClient.resetQueries("BookSellers");
      }, 500)
    );
  };

  /**
   * Query with pagination @staleTime: period in save data
   */
  const bookSellersList = useQuery(
    ["BookSellers", pageCount, filterQueries],
    () => BookSellers(pageCount || 1, limitPerPage, filterQueries),
    {
      retry: false,
      keepPreviousData: true,
      staleTime: 60000,
    }
  );

  const createBookSeller = useMutation(CreateBookSeller);
  const editBookSellers = useMutation(EditBookSellerById);
  const uploadCSV = useMutation(UploadBooksellersCSV);

  useEffect(() => {
    if (visibleCreateModal) {
      createBookSeller.reset();
      createForm.setValue("name", "");
      createForm.setValue("surname", "");
      createForm.setValue("email", "");
      createForm.setValue("companyName", "");
      createForm.setValue("code", "");
      createForm.setValue("city", "");
      createForm.setValue("province", "");
      createForm.setValue("address", "");
      createForm.setValue("zipCode", "");
      createForm.setValue("contry", "");
      createForm.setValue("telephone", "");
      createForm.setValue("website", "");
      createForm.setValue("facebook", "");
      createForm.setValue("chanel", "");
      createForm.setValue("clientGroup", "");
      createForm.setValue("vatNumber", "");
      createForm.setValue("goodsDestination", "");
      createForm.setValue("accountType", "");
      createForm.setValue("region", "");
      createForm.setValue("geographicArea", "");
      createForm.setValue("arianna", "");
    }
  }, [visibleCreateModal]);

  const editSubmit = async (data) => {
    const filtered = filterEmptyKeys(data);
    filtered.bookSellerId = editBookSellersId;

    await editBookSellers.mutateAsync(filtered);
    await queryClient.refetchQueries("BookSellers");
  };

  const createSubmit = async (data) => {
    const filtered = filterEmptyKeys(data);
    await createBookSeller.mutateAsync(filtered);
    await queryClient.refetchQueries("Users");
  };

  const uploadSubmit = async () => {
    if (file) {
      const form = new FormData();
      form.set("file", file);
      await uploadCSV.mutateAsync(form);
    } else {
      setShowError(true);
    }
  };

  const handleUpload = (e) => {
    const name = e.target.files[0].name.split(".")[0];
    setFileName(name);
    setShowError(false);
    setFile(e.target.files[0]);
    console.log(name);
  };

  const headRows = [
    "companyName",
    "city",
    "code",
    { key: "Active", label: t("Active") },
  ];
  const searchFieldNames = ["companyName", "city", "code"];
  const { searchProps } = useMultilineSearch(
    searchFieldNames,
    setFilterQueries
  );

  return (
    <>
      <Modal
        visible={visibleCreateModal}
        setVisible={handleVisibleCreateModal}
        title={createBookSeller.isSuccess ? t("success") : t("Create user")}
        type={createBookSeller.isSuccess ? "success" : "primary"}
        fetching={createBookSeller.isLoading}
        body={
          createBookSeller.isSuccess ? (
            t("Bookseller created successfully")
          ) : (
            <BookSellerForm allFields form={createForm} />
          )
        }
        toDoBtn={createBookSeller.isSuccess ? t("Close") : t("Submit")}
        toDoFunc={
          !createBookSeller.isSuccess
            ? createForm.handleSubmit(createSubmit)
            : handleVisibleCreateModal
        }
        cancelBtn={t("cancel")}
      />

      <Modal
        visible={visibleEditModal}
        setVisible={handleCloseEditModal}
        title={editBookSellers.isSuccess ? t("success") : t("Edit bookseller")}
        type={editBookSellers.isSuccess ? "success" : "primary"}
        fetching={editBookSellers.isLoading}
        body={
          editBookSellers.isSuccess ? (
            t("Booksellers data changed successfully")
          ) : (
            <BookSellerForm allFields form={editForm} />
          )
        }
        toDoBtn={editBookSellers.isSuccess ? t("Close") : t("Submit")}
        toDoFunc={
          !editBookSellers.isSuccess
            ? editForm.handleSubmit(editSubmit)
            : handleCloseEditModal
        }
        cancelBtn={t("cancel")}
      />

      <Modal
        visible={visibleUploadModal}
        setVisible={handleCloseUploadModal}
        title={uploadCSV.isSuccess ? t("success") : t("Edit bookseller")}
        type={uploadCSV.isSuccess ? "success" : "primary"}
        fetching={uploadCSV.isLoading}
        body={
          uploadCSV.isSuccess ? (
            t("Booksellers data changed successfully")
          ) : (
            <UploadInput
              onChange={handleUpload}
              fileName={fileName}
              error={showError}
              errorText={t("Please choose file")}
            />
          )
        }
        toDoBtn={uploadCSV.isSuccess ? t("Close") : t("Submit")}
        toDoFunc={!uploadCSV.isSuccess ? uploadSubmit : handleCloseUploadModal}
        cancelBtn={t("cancel")}
      />

      <CRow className="justify-content-start mt-3 pr-4 pl-4">
        <MultilineSearch
          className="col-sm-8 col-md-8 col-lg-8"
          {...searchProps}
        />
        {/* <Button
          variant='outline'
          wrapped
          size='md'
          color='primary'
          title={t('Upload')}
          onClick={handleVisibleUploadModal}
        />
        <Button
          variant='outline'
          wrapped
          size='md'
          color='primary'
          title={t('Create')}
          onClick={handleVisibleCreateModal}
        /> */}
      </CRow>
      <CRow className="justify-content-center pr-4 pl-4">
        {bookSellersList.isFetching ? (
          <Spiner />
        ) : (
          bookSellersList.isSuccess && (
            <RenderList
              header={t("Booksellers")}
              data={bookSellersList.data.data.booksellers.results}
              fields={headRows}
              currentPage={pageCount}
              limit={bookSellersList.data.data.booksellers.limit}
              setActivePage={setActivePage}
              totalPages={bookSellersList.data.data.booksellers.totalPages}
              scopedSlots={{
                Active: (item) => (
                  <td>
                    <CBadge color={item.active ? "success" : "primary"}>
                      {item.active ? t("Active") : t("Inactive")}
                    </CBadge>
                  </td>
                ),
                // Edit: (item) => (
                //   <td>
                //     <Button
                //       variant="outline"
                //       color="primary"
                //       size="sm"
                //       title={t("Edit")}
                //       onClick={() => handleOpenEditModal(item)}
                //     />
                //   </td>
                // ),
              }}
            />
          )
        )}
      </CRow>
    </>
  );
});

export { BookSellersPage };
