import React, { useEffect, useState } from "react";
import { CRow } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import {
  AgentObjectivesByAgentIdVsPublisherCode,
  AgentsPerfomancesByRolloutId,
} from "api/queries";
import { RenderList } from "components/RenderList";
import { Spiner } from "components/Spiner";
import { useHistory } from "react-router";
import { Button } from "components/Button";
import { CSVLink } from "react-csv";
import {
  MultilineSearch,
  useMultilineSearch,
} from "../../hooks/useMultilineSearch";
import { AllPublishers } from "api/mobileQueries";

const AllAgentsPerfomancesPage = React.memo((props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const limitPerPage = 50;
  const history = useHistory();
  const historyState = history.location.state;
  const rolloutId = historyState.rolloutId;
  const [pageCount, setPageCount] = useState(1);
  const [filterQueries, setFilterQueries] = useState([]);
  const [list, setList] = useState(null);
  const [csvList, setCSVList] = useState(null);
  const [valuesToUpdate, setValuesToUpdate] = useState([]);
  const [valuesToUpdateCSV, setValuesToUpdateCSV] = useState([]);
  const setActivePage = (page) => setPageCount(page);

  const queryFunc = () =>
    AgentsPerfomancesByRolloutId(
      rolloutId,
      pageCount,
      limitPerPage,
      filterQueries
    );

  /**
   * Query with pagination @staleTime: period in save data
   */
  const agentsPerfomancesList = useQuery(
    [
      "AgentsPerfomancesAll",
      rolloutId,
      pageCount || 1,
      limitPerPage,
      filterQueries,
    ],
    queryFunc,
    {
      retry: false,
      keepPreviousData: true,
      staleTime: 60000,
    }
  );

  const agentPerfomanceToCSV = useQuery(
    ["AgentPerfomanceToCSV", rolloutId],
    () => AgentsPerfomancesByRolloutId(rolloutId),
    {
      retry: false,
    }
  );

  const allPublishers = useQuery(["AllPublishers"], AllPublishers, {
    retry: false,
    keepPreviousData: true,
    staleTime: 60000,
  });

  const refreshQuery = () =>
    queryClient.removeQueries("AgentPerfomancesAll", { inactive: true });

  useEffect(() => {
    return refreshQuery;
  }, []);

  const header = [
    "surname",
    "bookEAN",
    "title",
    "author",
    "publisherCompanyName",
    "booksellerCompanyName",
    "price",
    "format",
    { key: "objective", label: t("National objective") },
    "agentPerfomance",
    "reservedCopies",
    "overDiscount",
    "extensionOfPayment",
  ];
  const searchFieldNames = [
    "surname",
    "booksellerCompanyName",
    "publisherCompanyName",
    "bookEAN",
    "price",
    "format",
  ];

  const searchCustomFields = [
    {
      id: "publisherCompanyName",
      type: "select",
      options: allPublishers.data
        ? allPublishers.data.data.map(({ value }) => ({ label: value, value }))
        : [],
    },
  ];
  const { searchProps } = useMultilineSearch(
    searchFieldNames,
    setFilterQueries,
    searchCustomFields
  );
  /**
   * For call every row
   * */

  const calcAgentPerfomance = (item, cb) => {
    AgentObjectivesByAgentIdVsPublisherCode(item.agentId, item.publisherCode)
      .then((record) => {
        if (record.data) {
          const update = {
            recordId: item._id,
            agentId: record.data.agentId,
            publisherCode: record.data.publisherCode,
            agentPerfomance: Math.round(
              (+item.objective / 100) * record.data.objective
            ),
          };
          cb([update]);
        }
      })
      .catch((err) => console.log(err));
  };
  const updateList = () => {
    const arrayOfList = agentsPerfomancesList.data.data.results;
    const newArr = arrayOfList.map((item) => {
      calcAgentPerfomance(item, setValuesToUpdate);
      return { ...item, agentPerfomance: "" };
    });
    setList(newArr);
  };

  const updateCSVList = () => {
    const arr = agentPerfomanceToCSV.data.data;
    const newArr = arr.map((item) => {
      calcAgentPerfomance(item, setValuesToUpdateCSV);
      return { ...item, agentPerfomance: "" };
    });
    setCSVList(newArr);
  };
  const checkValues = (list, valuesToUpdateFields) => {
    const newArr = list.map((item) => {
      let result = item;
      valuesToUpdateFields.forEach((values) => {
        if (
          item.agentId === values.agentId &&
          item.publisherCode === values.publisherCode &&
          item._id === values.recordId
        ) {
          result = {
            ...item,
            agentPerfomance: values.agentPerfomance,
          };
        }
      });
      return result;
    });
    return newArr;
  };
  const arrayCSV = [];

  useEffect(() => {
    if (list && valuesToUpdate) {
      const newArr = checkValues(list, valuesToUpdate);
      setList(newArr);
    }
  }, [valuesToUpdate]);

  useEffect(() => {
    if (csvList && valuesToUpdateCSV) {
      const newArr = checkValues(csvList, valuesToUpdateCSV);
      setCSVList(newArr);
    }
  }, [valuesToUpdateCSV]);

  useEffect(() => {
    if (agentsPerfomancesList.isSuccess) {
      updateList();
    }
  }, [agentsPerfomancesList.dataUpdatedAt]);

  useEffect(() => {
    if (agentPerfomanceToCSV.isSuccess) {
      updateCSVList();
    }
  }, [agentPerfomanceToCSV.dataUpdatedAt]);

  if (csvList) {
    arrayCSV.push(header);

    csvList.forEach((item) => {
      const dataToCSV = [
        item.surname,
        item.bookEAN,
        item.title,
        item.author,
        item.publisherCompanyName,
        item.booksellerCompanyName,
        item.price,
        item.format,
        item.objective,
        item.agentPerfomance,
        item.reservedCopies,
        item.overDiscount,
        item.extensionOfPayment,
      ];
      arrayCSV.push(dataToCSV);
    });
  }

  return (
    <>
      <CRow className="justify-content-end mt-3 pr-4 pl-4">
        <MultilineSearch
          className="col-sm-10 col-md-10 col-lg-10"
          {...searchProps}
        />
        <Button wrapped size="md" color="primary">
          <CSVLink data={arrayCSV}>
            <span style={{ color: "white" }}>{t("Export")}</span>
          </CSVLink>
        </Button>
      </CRow>
      <CRow className="justify-content-center pr-4 pl-4">
        {agentsPerfomancesList.isFetching ? (
          <Spiner />
        ) : (
          agentsPerfomancesList.isSuccess && (
            <RenderList
              header={t("Agent perfomances")}
              data={list}
              fields={header}
              currentPage={pageCount}
              limit={agentsPerfomancesList.data.data.limit}
              setActivePage={setActivePage}
              totalPages={agentsPerfomancesList.data.data.totalPages}
              scopedSlots={{
                objective: (item) => <td>{item.objective}</td>,
              }}
            />
          )
        )}
      </CRow>
    </>
  );
});

export { AllAgentsPerfomancesPage };
