import React, { useEffect, useState } from "react";
import { CBadge, CRow } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { CreateUser, EditUserById } from "api/mutations";
import { Modal } from "components/Modal";
import { CreateUserForm, languages, roles } from "./assets/CreateUserForm";
import { Button } from "components/Button";
import { Users } from "api/queries";
import { RenderList } from "components/RenderList";
import { Spiner } from "components/Spiner";
import {
  agentsPermissions,
  filterEmptyKeys,
  usersPermissions,
} from "../../libs/utils";
import { createSchema, editSchema } from "./assets/schemas";
import {
  MultilineSearch,
  useMultilineSearch,
} from "../../hooks/useMultilineSearch";

const UsersPage = React.memo(() => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [filterQueries, setFilterQueries] = useState([]);
  const searchFieldNames = ["name", "surname", "email", "language", "role"];
  const searchCustomFields = [
    {
      id: "language",
      type: "select",
      options: languages.map(({ value }) => ({ label: value, value })),
    },
    {
      id: "role",
      type: "select",
      options: roles.map(({ value }) => ({ label: value, value })),
    },
  ];
  const { searchProps } = useMultilineSearch(
    searchFieldNames,
    setFilterQueries,
    searchCustomFields
  );

  const limitPerPage = 50;

  const [pageCount, setPageCount] = useState(1);
  const [visibleCreateModal, setVisibleCreateModal] = useState(false);
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [editUserId, setEditUserId] = useState("");
  const [permissions, setPermissions] = useState(null);
  const [currentRoleFromEditForm, setCurrentRole] = useState(null);
  const [currentPermissions, setCurrentPermissions] = useState(null);
  const [withAgentFields, setWithAgentFields] = useState(false);
  const [currentAgentCode, setCurrentAgentCode] = useState("");
  const [currentAgentArea, setCurrentAgentArea] = useState("");
  const handleSetPermissions = (key) => {
    const updated = {
      ...permissions,
      [key]: !permissions[key],
    };
    setPermissions(updated);
  };

  const setActivePage = (page) => setPageCount(page);

  const profileForm = useForm({
    resolver: yupResolver(createSchema),
  });

  const editForm = useForm({
    resolver: yupResolver(editSchema),
  });

  const createUser = useMutation(CreateUser);
  const editUser = useMutation(EditUserById);

  const handleVisibleCreateModal = () => {
    profileForm.reset();
    setVisibleCreateModal(!visibleCreateModal);
  };

  const handleCloseEditModal = () => {
    /* Need it for fixed bug with edit form after success update */
    const close = new Promise((resolve) => {
      resolve(setVisibleEditModal(false));
    });
    close.then(() =>
      setTimeout(() => {
        editUser.reset();
        setCurrentAgentCode("");
      }, 300)
    );
  };

  const openEditModal = (item) => {
    // console.log(item)
    const user = item.role === "user";
    const agent = item.role === "agent" || item.role === "key_account";
    if (agent || user) {
      /* Need to compare logic in edit form when choose different role */
      setCurrentRole(item.role);
      setCurrentPermissions(item.permissions);
      setPermissions(item.permissions || usersPermissions);
      if (agent) {
        setWithAgentFields(true);
        setCurrentAgentCode(item.agentCode);
        setCurrentAgentArea(item.area);
      }
    }

    /* Add current value to form */
    editForm.setValue("name", item.name);
    editForm.setValue("surname", item.surname);
    editForm.setValue("email", item.email);
    editForm.setValue("language", item.language);
    editForm.setValue("companyName", item.companyName ? item.companyName : "");
    editForm.setValue("role", item.role);
    editForm.setValue("active", item.active ? t("active") : t("inactive"));
    editForm.setValue("agentCode", item.agentCode ? item.agentCode : "");
    editForm.setValue("area", item.area ? item.area : "");
    setVisibleEditModal(true);
    setEditUserId(item._id);
  };

  /**
   * Query with pagination @staleTime: period in save data
   */
  const usersList = useQuery(
    ["Users", pageCount, filterQueries],
    () => Users(pageCount || 1, limitPerPage, filterQueries),
    {
      retry: false,
      keepPreviousData: true,
      staleTime: 60000,
    }
  );

  useEffect(() => {
    if (visibleCreateModal) {
      createUser.reset();
      profileForm.setValue("name", "");
      profileForm.setValue("surname", "");
      profileForm.setValue("email", "");
      profileForm.setValue("companyName", "");
    }
  }, [visibleCreateModal]);

  const roleFromCreateForm = profileForm.watch("role");
  const roleFromEditForm = editForm.watch("role");

  useEffect(() => {
    const user = roleFromCreateForm === "user";
    const agent =
      roleFromCreateForm === "agent" || roleFromCreateForm === "key_account";
    const admin = roleFromCreateForm === "admin";

    if (user) {
      setWithAgentFields(false);
      setPermissions(usersPermissions);
    }
    if (agent) {
      setPermissions(agentsPermissions);
      setWithAgentFields(true);
    }
    if (admin) {
      setWithAgentFields(false);
    }
  }, [roleFromCreateForm]);

  useEffect(() => {
    const user = roleFromEditForm === "user";
    const agent =
      roleFromEditForm === "agent" || roleFromEditForm === "key_account";
    const admin = roleFromEditForm === "admin";

    if (admin) {
      setWithAgentFields(false);
    }
    if (roleFromEditForm === currentRoleFromEditForm) {
      setPermissions(currentPermissions);
      if (agent) {
        setWithAgentFields(true);
      }
    } else if (user) {
      setWithAgentFields(false);
      setPermissions(usersPermissions);
    } else if (agent) {
      setWithAgentFields(true);
      setPermissions(agentsPermissions);
    }
  }, [roleFromEditForm]);

  const createUserSubmit = async (data) => {
    const agentOrUser =
      data.role === "user" ||
      data.role === "agent" ||
      data.role === "key_account";

    if (agentOrUser) {
      data.permissions = permissions;
    }

    await createUser.mutateAsync(data);
    await queryClient.refetchQueries("Users");
  };

  const editUserSubmit = async (data) => {
    const filtered = filterEmptyKeys(data);
    const agentOrUser =
      filtered.role === "user" ||
      filtered.role === "agent" ||
      filtered.role === "key_account";
    filtered.userId = editUserId;

    if (agentOrUser) {
      filtered.permissions = permissions;
    }

    if (filtered.active === t("active")) {
      filtered.active = true;
    } else {
      filtered.active = false;
    }

    await editUser.mutateAsync(filtered);
    await queryClient.refetchQueries("Users");
  };

  return (
    <>
      <Modal
        visible={visibleCreateModal}
        setVisible={handleVisibleCreateModal}
        title={createUser.isSuccess ? t("success") : t("Create user")}
        type={createUser.isSuccess ? "success" : "primary"}
        fetching={createUser.isLoading}
        body={
          !createUser.isSuccess ? (
            <CreateUserForm
              withLanguage
              withDefaultValue
              withAgentFields={withAgentFields}
              profileForm={profileForm}
            />
          ) : (
            t("sucessfull user created")
          )
        }
        toDoBtn={createUser.isSuccess ? t("Close") : t("Submit")}
        toDoFunc={
          !createUser.isSuccess
            ? profileForm.handleSubmit(createUserSubmit)
            : handleVisibleCreateModal
        }
        cancelBtn={t("cancel")}
      />
      <Modal
        visible={visibleEditModal}
        setVisible={handleCloseEditModal}
        title={editUser.isSuccess ? t("success") : t("Edit user")}
        type={editUser.isSuccess ? "success" : "primary"}
        fetching={editUser.isLoading}
        body={
          !editUser.isSuccess ? (
            <CreateUserForm
              withLanguage
              withDefaultValue
              withActiveStatus
              currentAgentCode={currentAgentCode}
              currentAgentArea={currentAgentArea}
              profileForm={editForm}
              setPermissions={(e) => handleSetPermissions(e)}
            />
          ) : (
            t("User data updated successfully")
          )
        }
        toDoBtn={editUser.isSuccess ? t("Close") : t("Submit")}
        toDoFunc={
          !editUser.isSuccess
            ? editForm.handleSubmit(editUserSubmit)
            : handleCloseEditModal
        }
        cancelBtn={t("cancel")}
      />
      <CRow className="justify-content-end mt-3 pr-4 pl-4">
        <MultilineSearch className="col-10" {...searchProps} />
        <Button
          variant="outline"
          wrapped
          size="md"
          color="primary"
          title={t("Create")}
          onClick={handleVisibleCreateModal}
        />
      </CRow>
      <CRow className="justify-content-center pr-4 pl-4">
        {usersList.isFetching ? (
          <Spiner />
        ) : (
          usersList.isSuccess && (
            <RenderList
              header={t("Users")}
              data={usersList.data.data.results}
              fields={[
                "name",
                "surname",
                "email",
                "role",
                { key: "Active", label: t("Active") },
                {
                  key: "Edit",
                  label: t("Edit"),
                },
              ]}
              currentPage={pageCount}
              limit={usersList.data.data.limit}
              setActivePage={setActivePage}
              totalPages={usersList.data.data.totalPages}
              scopedSlots={{
                Active: (item) => (
                  <td>
                    <CBadge color={item.active ? "success" : "primary"}>
                      {item.active ? t("Active") : t("Inactive")}
                    </CBadge>
                  </td>
                ),
                Edit: (item) => (
                  <td>
                    <Button
                      variant="outline"
                      color="primary"
                      size="sm"
                      title={t("Edit")}
                      onClick={() => openEditModal(item)}
                    />
                  </td>
                ),
              }}
            />
          )
        )}
      </CRow>
    </>
  );
});

export { UsersPage };
