import { AgentBooksellersPublishersByAgentId } from 'api/queries';
import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { CBadge, CRow } from '@coreui/react';
import { RenderList } from 'components/RenderList';
import { Spiner } from 'components/Spiner';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { UpdateDiscount } from 'api/mutations';
import { Modal } from 'components/Modal';

const DiscountPage = React.memo((props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const history = useHistory();
  const arrOfFields = [
    'booksellerCompanyName',
    'booksellerCity',
    'booksellerCode',
    'booksellerDiscount',
    'extensionOfPayment',
    { key: 'Update', label: t('Update') },
  ];

  const limitPerPage = 50;
  const agent =
    props.user.role === 'agent' || props.user.role === 'key_account';
  const agentId = props.user._id;
  const agentCode = props.user.agentCode;
  const agentArea = props.user.area;
  let distinctValue = '';
  if (agent) {
    distinctValue = 'booksellerId';
  }
  const [filterQueries, setFilterQueries] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const setActivePage = (page) => setPageCount(page);
  const [updateData, setUpdateData] = useState(false);
  const [visibleSuccessModal, setVisibleSuccessModal] = useState(false);
  const handleVisibleSuccesModal = () => {
    setVisibleSuccessModal(!visibleSuccessModal);
  };

  const queryFunc = () =>
    AgentBooksellersPublishersByAgentId(
      agentId,
      distinctValue,
      pageCount || 1,
      limitPerPage,
      filterQueries,
    );

  const agentBooksellersPublishersList = useQuery(
    [
      'AgentBooksellersDetails',
      agentId,
      distinctValue,
      pageCount,
      limitPerPage,
      filterQueries,
    ],
    queryFunc,
    {
      retry: false,
      keepPreviousData: true,
      staleTime: 60000,
    },
  );

  const updatePublisherDiscount = useMutation(UpdateDiscount, {
    onSuccess: () => {
      queryClient.invalidateQueries(['Me']);
    },
  });

  useEffect(() => {
    setUpdateData(!updateData);
    if (agentBooksellersPublishersList.isSuccess) {
      agentBooksellersPublishersList.data.data.results.forEach((el) => {
        if (
          props.user.discounts.booksellerDiscount.some(
            (item) => item.booksellerId == el.booksellerId,
          )
        ) {
          const index = props.user.discounts.booksellerDiscount.findIndex(
            (ell) => ell.booksellerId == el.booksellerId,
          );
          el.discount = props.user.discounts.booksellerDiscount[index].discount
            ? props.user.discounts.booksellerDiscount[index].discount
            : 0;
          el.extensionOfPayment = props.user.discounts.booksellerDiscount[index]
            .extensionOfPayment
            ? props.user.discounts.booksellerDiscount[index].extensionOfPayment
            : 0;
        }
      });
    }
  }, [agentBooksellersPublishersList.dataUpdatedAt]);

  const setValueOfList = (id, field, value) => {
    if (agentBooksellersPublishersList.isSuccess) {
      agentBooksellersPublishersList.data.data.results.forEach((item) => {
        if (id === item.booksellerId) {
          item[field] = value;
        }
      });
    }
    setUpdateData(!updateData);
  };

  // add or update discount in List
  const handleUpdateDiscount = async (id) => {
    try {
      const data =
        await agentBooksellersPublishersList.data.data.results.filter(
          (el) => el._id == id,
        );
      const res = await updatePublisherDiscount.mutateAsync({
        discounts: {
          booksellerDiscount: [
            {
              booksellerId: data[0].booksellerId,
              discount: data[0].discount ? Number(data[0].discount) : 0,
              extensionOfPayment: data[0].extensionOfPayment
                ? Number(data[0].extensionOfPayment)
                : 0,
            },
          ],
        },
      });
      if (res.status === 200) {
        handleVisibleSuccesModal();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Modal
        visible={visibleSuccessModal}
        setVisible={handleVisibleSuccesModal}
        title={t('success')}
        type="success"
        body={t('Agent perfomance created successfully')}
        toDoBtn={t('Close')}
        toDoFunc={handleVisibleSuccesModal}
        cancelBtn={t('cancel')}
      />
      <CRow className="justify-content-center pr-4 pl-4">
        {agentBooksellersPublishersList.isFetching ? (
          <Spiner />
        ) : (
          agentBooksellersPublishersList.isSuccess && (
            <RenderList
              header={'Librerie'}
              data={agentBooksellersPublishersList.data.data.results}
              fields={arrOfFields}
              currentPage={pageCount}
              limit={agentBooksellersPublishersList.data.data.limit}
              setActivePage={setActivePage}
              totalPages={agentBooksellersPublishersList.data.data.totalPages}
              scopedSlots={{
                booksellerDiscount: (item) => (
                  <td>
                    <Input
                      withOutLabel
                      id={item._id}
                      type="number"
                      min={0}
                      max={100}
                      value={item.discount}
                      onChange={(e) =>
                        setValueOfList(
                          item.booksellerId,
                          'discount',
                          e.target.value,
                        )
                      }
                    />
                  </td>
                ),
                extensionOfPayment: (item) => (
                  <td>
                    <Input
                      withOutLabel
                      type="number"
                      min={0}
                      max={100}
                      value={item.extensionOfPayment}
                      onChange={(e) => {
                        setValueOfList(
                          item.booksellerId,
                          'extensionOfPayment',
                          e.target.value,
                        );
                      }}
                    />
                  </td>
                ),
                Update: (item) => (
                  <td>
                    <Button
                      variant="outline"
                      color="primary"
                      size="sm"
                      title={t('Update')}
                      onClick={() => {
                        handleUpdateDiscount(item._id);
                      }}
                    />
                  </td>
                ),
              }}
            />
          )
        )}
      </CRow>
    </>
  );
});

export { DiscountPage };
