import React from 'react'

const CheckBox = React.memo((props) => {
  const {
    id,
    onChange,
    checked
  } = props

  return (
    <div className='form-check ml-3'>
      <input
        className='form-check-input'
        type='checkbox'
        value=''
        id={id}
        checked={checked}
        onChange={onChange}
      />
      <label className='form-check-label' htmlFor={id}>
        {id}
      </label>
    </div>

  )
})

export { CheckBox }
