import React from 'react'
import * as SVGLoaders from 'svg-loaders-react'

const Loader = ({ children }) => {
  return (
    <div className='section'>
      <div className='loader'>
        <SVGLoaders.Bars stroke='#ffc034' />
      </div>
    </div>
  )
}

export default Loader
