import React from 'react'
import { Link, useParams } from 'react-router-dom'

import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { ResetPassword } from 'api/mutations'
import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import miniToastr from 'libs/minitoastr'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { ROUTES } from 'routes'

const schema = yup.object().shape({
  email: yup.string().email().required(),
  token: yup.string().required(),
  password: yup.string().min(8).required('Password is required'),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], "Passwords don't match").required('Confirm Password is required')
})

const ResetPasswordPage = (props) => {
  const { t } = useTranslation()
  const { token } = useParams()

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      token: token
    }
  })
  const mutation = useMutation(ResetPassword)

  const onSubmit = async data => {
    const response = await mutation.mutateAsync(data)
    if (response) {
      miniToastr.success(t('Your password has been successfully updated'))
      props.history.push('/auth/login')
    }
  }

  return (
    <div className='c-app c-default-layout flex-row align-items-center'>
      <CContainer>
        <CRow className='justify-content-center'>
          <CCol md='6'>
            <CCardGroup>
              <CCard className='p-4'>
                <CCardBody>
                  <CForm onSubmit={handleSubmit(onSubmit)}>
                    <h1>Reset Password</h1>
                    <p className='text-muted'>Enter your token and the new password</p>
                    <CInputGroup className='mb-3'>
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name='cil-user' />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type='email' placeholder='Email' name='email' id='email' innerRef={register} />
                    </CInputGroup>
                    <p>{errors.email?.message}</p>
                    <CInputGroup className='mb-3'>
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name='cil-lock-locked' />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type='text' placeholder='Token' name='token' id='token' innerRef={register} />
                    </CInputGroup>
                    <p>{errors.token?.message}</p>
                    <CInputGroup className='mb-4'>
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name='cil-lock-locked' />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type='password' placeholder='Password' autoComplete='current-password' name='password' id='password' innerRef={register} />
                    </CInputGroup>
                    <p>{errors.password?.message}</p>
                    <CInputGroup className='mb-4'>
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name='cil-lock-locked' />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type='password' placeholder='Password Confirmation' autoComplete='current-password' name='passwordConfirmation' id='passwordConfirmation' innerRef={register} />
                    </CInputGroup>
                    <p>{errors.passwordConfirmation?.message}</p>
                    <CRow>
                      <CCol xs='6'>
                        <CButton color='primary' type='submit' className='px-4'>Reset my password</CButton>
                      </CCol>
                      <CCol xs='6' className='text-right'>
                        <Link to={ROUTES.LOGIN} color='link' className='px-0'>Login</Link>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default ResetPasswordPage
