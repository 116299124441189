import React, { useEffect, useState, useRef } from "react";
import { CCard, CCardBody, CCardText, CCol, CRow } from "@coreui/react";
import { ReactSelect } from "../../components/ ReactSelect";
import { CChartDoughnut } from "@coreui/react-chartjs";
import { useQuery } from "react-query";
import {
  AgentBooksellerOnlyPublishers,
  Rollouts,
  RolloutsBooksByRolloutId,
  SumOfRolloutBookObjectiveByRolloutId,
  PerformanceByRolloutPublisherAndBook,
} from "../../api/queries";

const DashboardForAdminRedesign = () => {
  const [nationalObjective, setNationalObjective] = useState(0);
  const [performance, serPerformance] = useState([]);
  const [activeRollout, setActiveRollout] = useState(null);
  const [activePublishers, setActivePublishers] = useState([]);
  const [activeBooks, setActiveBooks] = useState([]);
  const [books, setBooks] = useState([]);

  const publishersRef = useRef();
  const booksRef = useRef();

  const rollouts = useQuery(["RolloutsDashboard"], () => Rollouts(), {
    retry: false,
    keepPreviousData: true,
    staleTime: 60000,
    onSuccess: ({ data }) => {
      const rollout = data.rollouts.find((rollout) => rollout.isCurrent);
      if (rollout)
        setActiveRollout({ value: rollout._id, label: rollout.name });
    },
  });
  const publishers = useQuery(
    ["AgentBooksellerOnlyPublishers"],
    () => AgentBooksellerOnlyPublishers(),
    {
      retry: false,
      keepPreviousData: true,
      staleTime: 60000,
    }
  );

  const updateBooks = async () => {
    const response = await RolloutsBooksByRolloutId(activeRollout.value);
    if (response.status === 200) {
      setBooks(response.data);
    }
  };

  const getPublisherBookQuery = () => {
    let query = "?";
    if (activePublishers.length)
      query += `&publisherIds=${getValues(activePublishers).join()}`;
    if (activeBooks.length)
      query += `&bookIds=${getValues(activeBooks).join()}`;
    return query;
  };

  const updateNationalObjectives = async () => {
    const query = getPublisherBookQuery();
    const response = await SumOfRolloutBookObjectiveByRolloutId(
      activeRollout.value,
      query
    );
    if (response.status === 200) {
      setNationalObjective(response.data);
    }
  };

  const updatePerformance = async () => {
    const query = getPublisherBookQuery();
    const response = await PerformanceByRolloutPublisherAndBook(
      activeRollout.value,
      query
    );
    if (response.status === 200) {
      serPerformance(response.data);
    }
  };

  useEffect(() => {
    if (activeRollout) {
      booksRef.current && booksRef.current.select.clearValue();
      publishersRef.current && publishersRef.current.select.clearValue();
      updateBooks();
      updateNationalObjectives();
      updatePerformance();
    }
  }, [activeRollout]);
  useEffect(() => {
    if (activeRollout) {
      updateNationalObjectives();
      updatePerformance();
    }
  }, [activePublishers, activeBooks]);

  const onInputChange = (value, action, data) => {
    if (value) {
      data.filter(
        (obj) =>
          obj.value.substring(0, value.length).toLowerCase() ===
          value.toLowerCase()
      );
    }
  };
  const calculateAchievements = () => {
    console.log("nationalObjective", nationalObjective);
    console.log("performance", performance);
    if (nationalObjective && performance.length) {
      const performanceAll = performance.reduce(
        (acc, x) => acc + x.performance,
        0
      );
      console.log("performanceAll ->", performanceAll);
      const achievements = Math.round(
        (performanceAll * 100) / nationalObjective
      );
      console.log("achievements ->", achievements);
      return achievements;
    } else {
      return 0;
    }
  };

  const calculateReserved = () => {
    if (performance.length) {
      const performanceAll = performance.reduce(
        (acc, x) => acc + x.performance,
        0
      );
      return performanceAll;
    } else {
      return 0;
    }
  };

  const getOptions = (data, labelField, valueField = "_id") =>
    data.map((el) => ({ label: el[labelField], value: el[valueField] }));
  const getValues = (data) => data.map((el) => el.value);

  return (
    <CRow className="mt-3 pr-4 pl-4">
      <CCol>
        <CCard>
          <CCardBody>
            <CCardText>Rollouts:</CCardText>
            <ReactSelect
              onInputChange={onInputChange}
              defaultInputValue={activeRollout?.label}
              onChange={setActiveRollout}
              options={
                rollouts.isSuccess
                  ? getOptions(rollouts.data.data.rollouts, "name")
                  : []
              }
            />
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody>
            <CCardText>Publishers:</CCardText>
            <ReactSelect
              inputRef={publishersRef}
              onInputChange={onInputChange}
              defaultInputValue={"All"}
              isMulti
              onChange={setActivePublishers}
              options={
                publishers.isSuccess
                  ? getOptions(publishers.data.data, "publisherCompanyName")
                  : []
              }
            />
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody>
            <CCardText>Books:</CCardText>
            <ReactSelect
              inputRef={booksRef}
              onInputChange={onInputChange}
              defaultInputValue={"All"}
              isMulti
              onChange={setActiveBooks}
              options={getOptions(books, "title", "ean")}
            />
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody>
            <CCardText>
              Obiettivo nazionale: {nationalObjective || "-"}
            </CCardText>
            <CCardText>Prenotati: {calculateReserved()}</CCardText>
            <CCardText>Traguardo: {calculateAchievements()}%</CCardText>
          </CCardBody>
        </CCard>

        <CCard>
          <CCardBody>
            <CChartDoughnut
              datasets={[
                {
                  backgroundColor: ["#321fdb", "#C0C0C0"],
                  data: [
                    calculateAchievements(),
                    100 - calculateAchievements(),
                  ],
                },
              ]}
              labels={["Achievement: %", "Total"]}
            />
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody>
            <CChartDoughnut
              datasets={[
                {
                  backgroundColor: [
                    "#C0B0C0",
                    "#FED6BC",
                    "#FFFADD",
                    "#DEF7FE",
                    "#E7ECFF",
                    "#C3FBD8",
                    "#F6FFF8",
                    "#B5F2EA",
                    "#C6D8FF",
                  ],
                  data: performance.length
                    ? performance.map((performance) => performance.performance)
                    : [100],
                },
              ]}
              labels={
                performance.length
                  ? performance.map((performance) => performance.agentArea)
                  : ["No data"]
              }
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default DashboardForAdminRedesign;
