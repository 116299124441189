import * as yup from 'yup'
import i18next from 'libs/i18n'

const createSchema = yup.object().shape({
  name: yup.string().required(i18next.t('Name is a requierd field')),
  surname: yup.string().required(i18next.t('Surname is a requierd field')),
  companyName: yup.string().optional(),
  agentCode: yup.string().optional(),
  email: yup.string().email(i18next.t('Must be a valid email')).required(i18next.t('Email is a requierd field')),
  language: yup.string().required(),
  area: yup.string().optional(),
  role: yup.string().required()
})

const editSchema = yup.object().shape({
  name: yup.string().optional(),
  surname: yup.string().optional(),
  companyName: yup.string().optional(),
  agentCode: yup.string().optional(),
  email: yup.string().email(i18next.t('Must be a valid email')).optional(),
  language: yup.string().optional(),
  area: yup.string().optional(),
  role: yup.string().optional(),
  active: yup.string().optional()
})

export {
  createSchema,
  editSchema
}
