import { CCol, CFormText, CInput, CLabel } from "@coreui/react";
import React from "react";
import { capitalizeFirstLetter } from "../libs/utils";

const Input = React.memo((props) => {
  const {
    value,
    onChange,
    error,
    type,
    id,
    placeholder,
    helperText,
    withOutLabel,
    min,
    max,
    inlineLabel,
    inlineLabelClassName,
    containerClassName,
  } = props;

  return (
    <div className={containerClassName}>
      {" "}
      {!withOutLabel && !inlineLabel && (
        <CCol md="3">
          <CLabel htmlFor={id}>{capitalizeFirstLetter(id)}</CLabel>
        </CCol>
      )}
      <CCol>
        <div className=" d-flex align-items-center">
          {inlineLabel && (
            <CLabel
              className={`m-0 col-form-label  text-truncate ${inlineLabelClassName}`}
              htmlFor={id}
            >
              {capitalizeFirstLetter(id)}
            </CLabel>
          )}
          <CInput
            type={type}
            value={value}
            onChange={onChange}
            id={id}
            min={min}
            max={max}
            name={id}
            placeholder={placeholder}
            className="mw-100"
          />
        </div>
        <CFormText className="help-block" color={error && "danger"}>
          {error || helperText}
        </CFormText>
      </CCol>
    </div>
  );
});

export { Input };
