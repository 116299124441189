import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import React from "react";

const PDFModal = React.memo((props) => {
  const { visible, setVisible, title, url } = props;

  return (
    <CModal fullscreen show={visible} onClose={setVisible} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>{title}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <iframe
          src={url}
          frameBorder="0"
          scrolling="auto"
          height="600px"
          width="100%"
        ></iframe>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" onClick={() => setVisible(false)}>
          Chiudi
        </CButton>
      </CModalFooter>
    </CModal>
  );
});

export { PDFModal };
