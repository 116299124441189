import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import {
  Sidebar,
  TheAside,
  TheHeader
} from './index'

const TheLayout = ({ children }) => {
  const darkMode = useSelector(state => state.darkMode)
  const classes = classNames(
    'c-app c-default-layout',
    darkMode && 'c-dark-theme'
  )

  return (
    <div className={classes}>
      <Sidebar />
      <TheAside />
      <div className='c-wrapper'>
        <TheHeader />
        <div className='c-body'>
          {children}
        </div>
        {/* <TheFooter /> */}
      </div>
    </div>
  )
}

export default TheLayout
