const ROLES = {
  USER: {
    ROLE: 'user',
    ROLLOUTS_MANAGEMENT: 'rolloutsManagement',
    AGENTS_MANAGEMENT: 'agentsManagement',
    WEIGHTS_MANAGEMENT: 'weightsManagement'
  },
  AGENT: {
    ROLE: 'agent',
    ORDERS_MANAGEMENT: 'ordersManagement',
    IMPORT_RUNS_MANAGEMENT: 'importRunsManagement'
  },
  ADMIN: 'admin',
  SUPER_ADMIN: 'superadmin',
  KEY_ACCOUNT: 'key_account',
}

export default ROLES
