import en from './en'
import it from './it'

const languages = {
  en: {
    translation: en
  },
  it: {
    translation: it
  }
}

export default languages
