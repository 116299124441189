// eslint-disable-next-line import/no-anonymous-default-export
export default {
  success: 'Successo',
  'sucessfull updated': 'I tuoi dati sono stati aggiornati con successo',
  'go home': 'Torna alla home',
  cancel: 'Cancella',
  'Change profile info': 'Cambia le informazioni di profilo',
  name: 'Nome',
  arianna: 'Arianna',
  geographicArea: 'Area geografica',
  surname: 'Cognome',
  email: 'Email',
  Active: 'Attivo',
  active: 'attivo',
  inactive: 'inattivo',
  Status: 'Stato',
  discounts: 'Sconti',
  rolloutsManagement: 'Gestione giri',
  agentsManagement: 'Gestione agenti',
  weightsManagement: 'Gestione pesi',
  ordersManagement: 'Gestione ordini',
  importRunsManagement: 'Gestione import giri',
  language: 'Lingua',
  Submit: 'Invia',
  Create: 'Crea',
  'Edit bookseller': 'Modifica libreria',
  'New bookseller': 'Nuova libreria',
  Booksellers: 'Librerie',
  companyName: 'Nome azienda',
  city: 'Città',
  code: 'Codice',
  Upload: 'Carica',
  'Choose file': 'Scegli file',
  'Create rollout': 'Crea giro',
  'Upload file': 'Carica file',
  'Edit Rollout': 'Modifica Giro',
  startDate: 'Data inizio',
  endDate: 'Data fine',
  'Agents code': 'Codice agente',
  'Rollout books': 'Libri del giro',
  ean: 'EAN',
  title: 'Titolo',
  format: 'Formato',
  price: 'Prezzo',
  author: 'Autore',
  publisherCode: 'Codice editore',
  publisherCompanyName: 'Editore',
  'Agent objectives': "Obiettivi dell'agente",
  'Agent name': 'Nome agente',
  'Agent surname': 'Cognome agente',
  booksellerCompanyName: 'Libreria',
  booksellerCode: 'Codice libreria',
  booksellerDiscount: 'Sconto Libreria',
  'Agents Booksellers Publishers': 'Agenti Librerie Editori',
  'Agents Booksellers Publishers data changed successfully':
    'I dati sono stati aggiornati con successo',
  'Handle bookseller': 'Gestisci libreria',
  'Booksellers company name': 'Nome libreria',
  'Booksellers code': 'Codice libreria',
  'Agent perfomance': 'Obiettivo agente',
  Rollouts: 'Giri',
  'Agent Perfomances': 'Obiettivi agente',
  'Agent perfomances': 'Obiettivi agente',
  'National objective': 'Obiettivo nazionale',
  Achievement: 'Traguardo',
  All: 'All',

  'Please enter your name': 'Inserisci il nome',
  'Please enter your surname': 'Inserisci il cognome',
  'Please enter your email': 'Inserisci la email',
  'Please enter name': 'Inserisci  il nome',
  'Please enter surname': 'Inserisci il cognome',
  'Please enter email': 'Inserisci la email',
  'Please enter company name': 'Inserisci il nome azienda',
  'Please enter code': 'Inserisci il codice',
  'Please enter city': 'Inserisci la città',
  'Please enter province': 'Inserisci la provincia',
  'Please enter address': "Inserisci l'indirizzo",
  'Please enter zip code': 'Inserisci il CAP',
  'Please enter contry': 'Inserisci lo stato',
  'Please enter telephone': 'Inserisci il tlefono',
  'Please enter website': 'Inserisci il sito web',
  'Please enter facebook': 'Inserisci facebook',
  'Please enter chanel': 'Inserisci il canale',
  'Please enter clients group': 'Inserisci il gruppo clienti',
  'Please enter VAT number': 'Inserisci la P.IVA',
  'Please enter goods destination': 'Inserisci la destinazione dei beni',
  'Please enter accounts type': 'Inserisci il ruolo',
  'Please enter region': 'Inserisci la regione',
  'Please enter geographic area': "Inserisci l'area geografica",
  'Please enter arianna': 'Inserisci arianna',

  'Change password': 'Cambia password',
  'Current password': 'Password attuale',
  'Please enter your current password': 'Inserici la password attuale',
  'new password': 'nuova password',
  'Please enter your new password': 'Inserisci la nuova password',
  'confirm password': 'conferma password',
  'Please confirm your new password': 'conferma la nuova password',
  'Please enter enter date': 'Inserisci una data',
  'Create user': 'Crea utente',
  'Max limit achieved': 'Numero massimo raggiunto',
  'sucessfull user created': 'Utente creato con successo',
  'Booksellers data changed successfully': 'Dati cambiati con successo',
  'Rollouts book data changed successfully': 'Dati cambiati con successo',
  'Rollout changed successfully': 'Dati cambiati con successo',
  'Agent objectives data changed successfully': 'Dati cambiati con successo',
  'Agent perfomance created successfully': 'Dati cambiati con successo',

  Close: 'Chiudi',
  Update: 'Aggiorna',

  'New user': 'Nuovo utente',
  'Enter name': 'Inserisci il nome',
  'Enter surname': 'Inserisci il cognome',
  'Enter email': 'Inserisci la email',
  'Enter company name': 'Inserisci il nome azienda',
  'Enter facebook': 'Inserisci facebook',
  'Enter website': 'Inserisci il sito web',
  'Enter telephone': 'Inserisci il telefono',
  'Enter contry': 'Inserisci lo stato',
  'Enter zip code': 'Inserisci il CAP',
  'Enter address': "Inserisci l'indirizzo",
  'Enter province': 'Inserisci la provincia',
  'Enter city': 'Inserisci la città',
  'Enter code': 'Inserisci il codice',
  'Enter chanel': 'Inserisci il canale',
  'Enter clients group': 'Inserisci il gruppo clienti',
  'Enter VAT number': 'Inserisci la P.IVA',
  'Enter clients goods destination': 'Inserisci la destinazione dei beni',
  'Enter accounts type': 'Inserisci il ruolo',
  'Enter region': 'Inserisci la regione',
  'Enter geographic area': "Inserisci l'area geografica",
  'Enter arianna': 'Inserisci arianna',
  role: 'Ruolo',

  'Name is a requierd field': 'Il nome è obbligatorio',
  'Surname is a requierd field': 'Il cognome è obbligatorio',
  'Email is a requierd field': "I'email è obbligatoria",
  'Must be a valid email': 'Deve essere una email valida',
  'Company name is a required field': 'Il nome azienda è obbligatorio',
  'Code is a required field': 'Il codice è obbligatorio',
  'Current password is a required field': 'La password attuale è obbligatoria',
  'New password is a required field':
    'La nuova password attuale è obbligatoria',
  'Confirm password is a required field': 'La conferma password è obbligatoria',
  'Confirm password have to match with new password':
    'La conferma password deve coincidere con la nuova password',
  'Start date is a required field': 'Data inizio è obbligatoria',
  'End date is a required field': 'Data fine è obbligatoria',

  Filter: 'Filtra',
  'User data updated successfully': 'Dati aggiornati con successo',
  Edit: 'Modifica',

  Company: 'Azienda',
  Code: 'Codice',
  City: 'Città',
  province: 'provincia',
  address: 'Indirizzo',
  'Zip code': 'CAP',
  Country: 'Nazione',
  telephone: 'Telefono',
  website: 'Sito web',
  facebook: 'Facebook',
  absorptionIndex: 'Indice di assorbimento',
  agentCode: 'Codice agente',
  booksellerProvince: 'Privincia libreria',
  bookEAN: 'EAN',
  agentPerfomance: 'Obiettivo assegnato',
  reservedCopies: 'Prenotato',
  overDiscount: 'Sovrasconto',
  extensionOfPayment: 'Dilazione pagamento',
  Search: 'Cerca',
  'Drop Filters': 'Rimuovi Filtri',
  Details: 'Dettagli',
  booksellerCity: 'Città libreria',
  personalObjective: 'Obiettivo assegnato',
  'Agents Objectives': 'Obiettivi agente',
  Users: 'Utenti',
  progressive: 'Progressivo',
  year: 'Anno',
  isExtra: 'Extra giro',
};
