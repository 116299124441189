import { CCol, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CLabel } from '@coreui/react'
import React from 'react'
import { capitalizeFirstLetter } from '../libs/utils'

const DropDown = React.memo((props) => {
  const { value, onChange, id, options } = props

  return (
    <>
      <CCol md='3'>
        <CLabel htmlFor={id}>{capitalizeFirstLetter(id)}</CLabel>
      </CCol>
      <CCol xs='12' md='9'>
        <CDropdown className='m-1' id={id} value={value} name={id}>
          <CDropdownToggle color='secondary'>
            {value}
          </CDropdownToggle>
          <CDropdownMenu>
            {options.map((item) => {
              return (
                <React.Fragment key={item.value}>
                  <CDropdownItem onClick={() => onChange(item.value)}>
                    {item.value}
                  </CDropdownItem>
                </React.Fragment>
              )
            })}
          </CDropdownMenu>
        </CDropdown>
      </CCol>
    </>
  )
})

export { DropDown }
