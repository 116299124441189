import { MobileAxios } from "../libs/axios";
import { urlWithQuery } from "libs/utils";

export const AgentBooksellersPublishersByRolloutId = async (rolloutId) => {
  const url = `/rollouts/${rolloutId}/publishers`;
  const result = await MobileAxios.authenticated().get(url);
  return result;
};

export const AgentNationalObjectiveByRolloutId = async (
  rolloutId,
  publisherId
) => {
  let url = `/stats/objective-sum/${rolloutId}`;
  if (publisherId) {
    url += `?publisherId=${publisherId}`;
  }
  const result = await MobileAxios.authenticated().get(url);
  return result;
};

export const AgentMyPerformanceByRolloutId = async (rolloutId, publisherId) => {
  let url = "/stats/reserved-copies/sum?";

  if (publisherId) {
    url += `publisherId=${publisherId}`;
  } else if (rolloutId) {
    url += `rolloutId=${rolloutId}`;
  }
  const result = await MobileAxios.authenticated().get(url);
  return result;
};

export const RolloutBookPreview = async (rolloutBookId) => {
  const url = `/rollout-books/${rolloutBookId}/download`;
  const result = await MobileAxios.authenticated().get(url, {
    responseType: "blob",
  });
  return result;
};

export const AgentMyObjectives = async (page, size, agentId) => {
  const url = `/agent-objective/`;
  url = urlWithQuery(url, page, size, { agentId: agentId });
  const result = await MobileAxios.authenticated().get(url);
  return result;
};

export const AllPublishers = async (rolloutId) => {
  const url = `/rollouts/all-publishers`;
  const result = await MobileAxios.authenticated().get(url);
  return result;
};
