import React, { useEffect, useState } from "react";
import { CCard, CCardBody, CCardText, CCol, CRow } from "@coreui/react";
import { ReactSelect } from "../../components/ ReactSelect";
import { CChartDoughnut } from "@coreui/react-chartjs";
import { useQuery } from "react-query";
import { Rollouts } from "../../api/queries";
import {
  AgentBooksellersPublishersByRolloutId,
  AgentMyPerformanceByRolloutId,
  AgentNationalObjectiveByRolloutId,
} from "../../api/mobileQueries";

const DashboardForAgent = ({ agentId }) => {
  const [nationalObjective, setNationalObjective] = useState(null);
  const [myPerformance, setMyPerformance] = useState(null);
  const [myObjective, setMyObjective] = useState(0);
  const [activeRollout, setActiveRollout] = useState(null);
  const [activePublisher, setActivePublisher] = useState(null);
  const [publishers, setPublishers] = useState([]);

  const rollouts = useQuery(["RolloutsDashboard"], () => Rollouts(), {
    retry: false,
    keepPreviousData: true,
    staleTime: 60000,
    onSuccess: ({ data }) => {
      const rollout = data.rollouts.find((rollout) => rollout.isCurrent);
      if (rollout) {
        setActiveRollout({ value: rollout._id, label: rollout.name });
      }
    },
  });

  const updateNationalObjective = async () => {
    const response = await AgentNationalObjectiveByRolloutId(
      activeRollout?.value,
      activePublisher?.value
    );
    if (response.status === 200) {
      setNationalObjective(response.data[0]?.sum);
      if (response.data[0]?.objective > 0) {
        const personal = Math.round(
          (response.data[0]?.sum * response.data[0]?.objective) / 100
        );
        setMyObjective(personal);
      } else {
        setMyObjective(0);
      }
    }
  };

  const updateMyPerformance = async () => {
    const response = await AgentMyPerformanceByRolloutId(
      activeRollout?.value,
      activePublisher?.value
    );
    if (response.status === 200) {
      setMyPerformance(response.data[0]?.sum);
    }
  };

  const updatePublishers = async () => {
    const response = await AgentBooksellersPublishersByRolloutId(
      activeRollout?.value
    );
    if (response.status === 200) {
      setPublishers(response.data);
    }
  };

  useEffect(() => {
    if (activeRollout) {
      updatePublishers();
      updateNationalObjective();
      updateMyPerformance();
    }
  }, [activeRollout]);
  useEffect(() => {
    if (activeRollout) {
      updateNationalObjective();
      updateMyPerformance();
    }
  }, [activePublisher]);

  const onInputChange = (value, action, data) => {
    if (value) {
      data.filter(
        (obj) =>
          obj.value.substring(0, value.length).toLowerCase() ===
          value.toLowerCase()
      );
    }
  };

  const calculateAchievements = () => {
    if (nationalObjective && myPerformance && myObjective > 0) {
      return Math.round((myPerformance / myObjective) * 100);
    } else {
      return 0;
    }
  };

  return (
    <CRow className="mt-3 pr-4 pl-4">
      <CCol>
        <CCard>
          <CCardBody>
            <CCardText>Rollouts:</CCardText>
            <ReactSelect
              onInputChange={onInputChange}
              defaultInputValue={activeRollout?.label}
              onChange={setActiveRollout}
              options={
                rollouts.isSuccess
                  ? rollouts.data.data.rollouts.map((rollout) => ({
                      label: rollout.name,
                      value: rollout._id,
                    }))
                  : []
              }
            />
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody>
            <CCardText>Publishers:</CCardText>
            <ReactSelect
              onInputChange={onInputChange}
              onChange={setActivePublisher}
              options={[
                { label: "", value: null },
                ...publishers.map((publisher) => ({
                  label: publisher.label,
                  value: publisher._id,
                })),
              ]}
            />
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody>
            <CCardText>
              Obiettivo nazionale: {nationalObjective || "-"}
            </CCardText>
            <CCardText>Obiettivo personale: {myObjective || "-"}</CCardText>
            <CCardText>Le mie prenotazioni: {myPerformance || "-"}</CCardText>
            <CCardText>
              Performance sul personale: {calculateAchievements()}%
            </CCardText>
          </CCardBody>
        </CCard>

        <CCard>
          <CCardBody>
            <CChartDoughnut
              datasets={[
                {
                  backgroundColor: ["#321fdb", "#C0C0C0"],
                  data: [
                    calculateAchievements(),
                    100 - calculateAchievements(),
                  ],
                },
              ]}
              labels={["Achievement: %", "Total"]}
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default DashboardForAgent;
