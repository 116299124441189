import { CFormText } from '@coreui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

const UploadInput = React.memo((props) => {
  const { onChange, fileName, error, errorText } = props
  const { t } = useTranslation()
  return (
    <>
      <div className='d-flex align-items-center'>
        <label className='upload-label'>
          <input
            type='file'
            size='60'
            accept='.csv'
            onChange={(e) => onChange(e)}
          />

          <div className='upload-btn'>
            <p className='upload-text'>{t('Upload')}</p>
          </div>
        </label>
        <p className='ml-1 uploaded-name'>
          {fileName}
        </p>
      </div>
      <CFormText className='help-block' color={error && 'danger'}>
        {(error && errorText) || t('Choose file')}
      </CFormText>
    </>
  )
})

export { UploadInput }
