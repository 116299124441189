import React from 'react'
import { CCard, CCardBody, CForm, CFormGroup } from '@coreui/react'
import { useTranslation } from 'react-i18next'
import { Input } from 'components/Input'
import { DropDown } from 'components/DropDown'
import { Controller } from 'react-hook-form'
import i18next from 'libs/i18n'
import { CheckBox } from 'components/CheckBox'

export const languages = [{ value: 'it' }, { value: 'en' }]

export const roles = [
  { value: 'user' },
  { value: 'admin' },
  { value: 'agent' },
  { value: 'key_account' }
]

const activeValues = [
  { value: i18next.t('active') },
  { value: i18next.t('inactive') }
]

const areas = [
  { value: 'NE' },
  { value: 'NO' },
  { value: 'CN' },
  { value: 'C' },
  { value: 'S' },
  { value: 'SS' },
  { value: 'ALL' }
]

const CreateUserForm = React.memo(props => {
  const {
    profileForm,
    withLanguage,
    withDefaultValue,
    withActiveStatus,
    withPermissions,
    withAgentFields,
    currentAgentCode,
    currentAgentArea,
    permissions,
    setPermissions
  } = props
  const { t } = useTranslation()

  const permissionsRender = () => {
    const arr = []
    for (const key in permissions) {
      const obj = (
        <CheckBox
          key={key}
          id={t(key)}
          checked={permissions[key]}
          onChange={() => setPermissions(key)}
        />
      )
      arr.push(obj)
    }
    return arr
  }

  return (
    <CCard>
      {/* <CCardHeader>{t("Create user")}</CCardHeader> */}
      <CCardBody>
        <CForm className='form-horizontal'>
          <CFormGroup row>
            <Controller
              name='name'
              control={profileForm.control}
              defaultValue=''
              render={({ onChange, value }) => (
                <Input
                  id={t('name')}
                  helperText={t('Please enter name')}
                  placeholder={t('Enter name')}
                  value={value}
                  onChange={onChange}
                  error={profileForm.errors.name?.message}
                />
              )}
            />
          </CFormGroup>

          <CFormGroup row>
            <Controller
              name='surname'
              control={profileForm.control}
              defaultValue=''
              render={({ onChange, value }) => (
                <Input
                  id={t('surname')}
                  helperText={t('Please enter surname')}
                  placeholder={t('Enter surname')}
                  value={value}
                  onChange={onChange}
                  error={profileForm.errors.surname?.message}
                />
              )}
            />
          </CFormGroup>

          <CFormGroup row>
            <Controller
              name='email'
              control={profileForm.control}
              defaultValue=''
              render={({ onChange, value }) => (
                <Input
                  id={t('email')}
                  helperText={t('Please enter email')}
                  placeholder={t('Enter email')}
                  value={value}
                  onChange={onChange}
                  error={profileForm.errors.email?.message}
                />
              )}
            />
          </CFormGroup>

          <CFormGroup row>
            <Controller
              name='companyName'
              control={profileForm.control}
              defaultValue=''
              render={({ onChange, value }) => (
                <Input
                  id={t('Company')}
                  helperText={t('Please enter company name')}
                  placeholder={t('Enter company name')}
                  value={value}
                  onChange={onChange}
                  error={profileForm.errors.companyName?.message}
                />
              )}
            />
          </CFormGroup>

          {withLanguage && (
            <CFormGroup row>
              <Controller
                name='language'
                control={profileForm.control}
                defaultValue='en'
                render={({ onChange, value }) => (
                  <DropDown
                    value={value}
                    onChange={onChange}
                    id={t('language')}
                    options={languages}
                  />
                )}
              />
            </CFormGroup>
          )}

          <CFormGroup row>
            <Controller
              name='role'
              control={profileForm.control}
              defaultValue={withDefaultValue ? 'user' : ''}
              render={({ onChange, value }) => (
                <DropDown
                  value={value}
                  onChange={onChange}
                  id={t('role')}
                  options={roles}
                />
              )}
            />
          </CFormGroup>
          {withActiveStatus && (
            <CFormGroup row>
              <Controller
                name='active'
                control={profileForm.control}
                defaultValue={withDefaultValue ? t('active') : ''}
                render={({ onChange, value }) => (
                  <DropDown
                    value={value}
                    onChange={onChange}
                    id={t('Status')}
                    options={activeValues}
                  />
                )}
              />
            </CFormGroup>
          )}
          {withAgentFields && (
            <>
              <CFormGroup row>
                <Controller
                  name='agentCode'
                  control={profileForm.control}
                  defaultValue={currentAgentCode}
                  render={({ onChange, value }) => (
                    <Input
                      id={t('Agents code')}
                      helperText={t('Please enter code')}
                      placeholder={t('Enter code')}
                      value={value}
                      onChange={onChange}
                      error={profileForm.errors.agentCode?.message}
                    />
                  )}
                />
              </CFormGroup>
              <CFormGroup row>
                <Controller
                  name='area'
                  control={profileForm.control}
                  defaultValue={currentAgentArea || 'NE'}
                  render={({ onChange, value }) => (
                    <DropDown
                      value={value}
                      onChange={onChange}
                      id={t('area')}
                      options={areas}
                    />
                  )}
                />
              </CFormGroup>
            </>
          )}
          {withPermissions && (
            <CFormGroup className='d-flex flex-row'>
              <p className='mr-4'>{t('Permissions')}</p>
              <div className='d-flex flex-row flex-wrap'>
                {permissionsRender()}
              </div>
            </CFormGroup>
          )}
        </CForm>
      </CCardBody>
    </CCard>
  )
})

export { CreateUserForm }
