import React from 'react'
import Select from 'react-select'

const ReactSelect = React.memo((props) => {
  const {
    className,
    options,
    onChange,
    defaultInputValue,
    inputValue,
    onInputChange,
    inputRef,
    isMulti
  } = props

  return (
    <Select
      ref={inputRef}
      placeholder={defaultInputValue}
      inputValue={inputValue}
      isMulti={isMulti}
      onInputChange={(value, action) => onInputChange(value, action, options)}
      className={className}
      options={options}
      onChange={onChange}
    />
  )
})

export { ReactSelect }
