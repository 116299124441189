import React from 'react'
import { CCard, CCardBody, CCol, CForm, CFormGroup } from '@coreui/react'
import { useTranslation } from 'react-i18next'
import { Input } from 'components/Input'
// import { DropDown } from 'components/DropDown';
import { Controller } from 'react-hook-form'

const row = 'd-flex justify-content-around'

const BookSellerForm = React.memo((props) => {
  const { form, allFields } = props

  const { t } = useTranslation()

  return (
    <CCard>
      <CCardBody>
        <CForm>
          {allFields && (
            <CFormGroup className={row}>
              <Controller
                name='name'
                control={form.control}
                defaultValue=''
                render={({ onChange, value }) => (
                  <Input
                    id={t('name')}
                    helperText={t('Please enter name')}
                    placeholder={t('Enter name')}
                    value={value}
                    withOutLabel
                    onChange={onChange}
                    error={form.errors.name?.message}
                  />
                )}
              />
              <Controller
                name='surname'
                control={form.control}
                defaultValue=''
                render={({ onChange, value }) => (
                  <Input
                    id={t('surname')}
                    helperText={t('Please enter surname')}
                    placeholder={t('Enter surname')}
                    value={value}
                    withOutLabel
                    onChange={onChange}
                    error={form.errors.surname?.message}
                  />
                )}
              />
            </CFormGroup>
          )}

          {allFields && (
            <CFormGroup className={row}>
              <Controller
                name='email'
                control={form.control}
                defaultValue=''
                render={({ onChange, value }) => (
                  <Input
                    id={t('email')}
                    helperText={t('Please enter email')}
                    placeholder={t('Enter email')}
                    value={value}
                    withOutLabel
                    onChange={onChange}
                    error={form.errors.email?.message}
                  />
                )}
              />
              <Controller
                name='companyName'
                control={form.control}
                defaultValue=''
                render={({ onChange, value }) => (
                  <Input
                    id={t('Company')}
                    helperText={t('Please enter company name')}
                    placeholder={t('Enter company name')}
                    value={value}
                    withOutLabel
                    onChange={onChange}
                    error={form.errors.companyName?.message}
                  />
                )}
              />
            </CFormGroup>
          )}

          {allFields && (
            <CFormGroup className={row}>
              <Controller
                name='code'
                control={form.control}
                defaultValue=''
                render={({ onChange, value }) => (
                  <Input
                    id={t('Code')}
                    helperText={t('Please enter code')}
                    placeholder={t('Enter code')}
                    value={value}
                    withOutLabel
                    onChange={onChange}
                    error={form.errors.code?.message}
                  />
                )}
              />
              <Controller
                name='city'
                control={form.control}
                defaultValue=''
                render={({ onChange, value }) => (
                  <Input
                    id={t('City')}
                    helperText={t('Please enter city')}
                    placeholder={t('Enter city')}
                    value={value}
                    withOutLabel
                    onChange={onChange}
                    error={form.errors.city?.message}
                  />
                )}
              />
            </CFormGroup>
          )}

          {allFields && (
            <CFormGroup className={row}>
              <Controller
                name='province'
                control={form.control}
                defaultValue=''
                render={({ onChange, value }) => (
                  <Input
                    id={t('province')}
                    helperText={t('Please enter province')}
                    placeholder={t('Enter province')}
                    value={value}
                    withOutLabel
                    onChange={onChange}
                    error={form.errors.province?.message}
                  />
                )}
              />
              <Controller
                name='address'
                control={form.control}
                defaultValue=''
                render={({ onChange, value }) => (
                  <Input
                    id={t('address')}
                    helperText={t('Please enter address')}
                    placeholder={t('Enter address')}
                    value={value}
                    withOutLabel
                    onChange={onChange}
                    error={form.errors.address?.message}
                  />
                )}
              />
            </CFormGroup>
          )}

          {allFields && (
            <CFormGroup className={row}>
              <Controller
                name='zipCode'
                control={form.control}
                defaultValue=''
                render={({ onChange, value }) => (
                  <Input
                    id={t('Zip code')}
                    helperText={t('Please enter zip code')}
                    placeholder={t('Enter zip code')}
                    value={value}
                    withOutLabel
                    onChange={onChange}
                    error={form.errors.province?.message}
                  />
                )}
              />
              <Controller
                name='country'
                control={form.control}
                defaultValue=''
                render={({ onChange, value }) => (
                  <Input // dropDown
                    id={t('Country')}
                    helperText={t('Please enter contry')}
                    placeholder={t('Enter contry')}
                    value={value}
                    withOutLabel
                    onChange={onChange}
                    error={form.errors.country?.message}
                  />
                )}
              />
            </CFormGroup>
          )}

          {allFields && (
            <CFormGroup className={row}>
              <Controller
                name='telephone'
                control={form.control}
                defaultValue=''
                render={({ onChange, value }) => (
                  <Input
                    id={t('telephone')}
                    helperText={t('Please enter telephone')}
                    placeholder={t('Enter telephone')}
                    value={value}
                    withOutLabel
                    onChange={onChange}
                    error={form.errors.telephone?.message}
                  />
                )}
              />
              <Controller
                name='website'
                control={form.control}
                defaultValue=''
                render={({ onChange, value }) => (
                  <Input
                    id={t('website')}
                    helperText={t('Please enter website')}
                    placeholder={t('Enter website')}
                    value={value}
                    withOutLabel
                    onChange={onChange}
                    error={form.errors.website?.message}
                  />
                )}
              />
            </CFormGroup>
          )}

          {allFields && (
            <CFormGroup className={row}>
              <Controller
                name='facebook'
                control={form.control}
                defaultValue=''
                render={({ onChange, value }) => (
                  <Input
                    id={t('facebook')}
                    helperText={t('Please enter facebook')}
                    placeholder={t('Enter facebook')}
                    value={value}
                    withOutLabel
                    onChange={onChange}
                    error={form.errors.website?.message}
                  />
                )}
              />
              <Controller
                name='chanel'
                control={form.control}
                defaultValue=''
                render={({ onChange, value }) => (
                  <Input
                    id={t('chanel')}
                    helperText={t('Please enter chanel')}
                    placeholder={t('Enter chanel')}
                    value={value}
                    withOutLabel
                    onChange={onChange}
                    error={form.errors.website?.message}
                  />
                )}
              />
            </CFormGroup>
          )}
          {allFields && (
            <CFormGroup className={row}>
              <Controller
                name='clientGroup'
                control={form.control}
                defaultValue=''
                render={({ onChange, value }) => (
                  <Input
                    id={t('clientGroup')}
                    helperText={t('Please enter clients group')}
                    placeholder={t('Enter clients group')}
                    value={value}
                    withOutLabel
                    onChange={onChange}
                    error={form.errors.website?.message}
                  />
                )}
              />
              <Controller
                name='vatNumber'
                control={form.control}
                defaultValue=''
                render={({ onChange, value }) => (
                  <Input
                    id={t('vatNumber')}
                    helperText={t('Please enter VAT number')}
                    placeholder={t('Enter VAT number')}
                    value={value}
                    withOutLabel
                    onChange={onChange}
                    error={form.errors.website?.message}
                  />
                )}
              />
            </CFormGroup>
          )}
          {allFields && (
            <CFormGroup className={row}>
              <Controller
                name='goodsDestination'
                control={form.control}
                defaultValue=''
                render={({ onChange, value }) => (
                  <Input
                    id={t('goodsDestination')}
                    helperText={t('Please enter goods destination')}
                    placeholder={t('Enter clients goods destination')}
                    value={value}
                    withOutLabel
                    onChange={onChange}
                    error={form.errors.website?.message}
                  />
                )}
              />
              <Controller
                name='accountType'
                control={form.control}
                defaultValue=''
                render={({ onChange, value }) => (
                  <Input
                    id={t('accountType')}
                    helperText={t('Please enter accounts type')}
                    placeholder={t('Enter accounts type')}
                    value={value}
                    withOutLabel
                    onChange={onChange}
                    error={form.errors.website?.message}
                  />
                )}
              />
            </CFormGroup>
          )}
          {allFields && (
            <CFormGroup className={row}>
              <Controller
                name='region'
                control={form.control}
                defaultValue=''
                render={({ onChange, value }) => (
                  <Input
                    id={t('region')}
                    helperText={t('Please enter region')}
                    placeholder={t('Enter region')}
                    value={value}
                    withOutLabel
                    onChange={onChange}
                    error={form.errors.website?.message}
                  />
                )}
              />
              <Controller
                name='geographicArea'
                control={form.control}
                defaultValue=''
                render={({ onChange, value }) => (
                  <Input
                    id={t('geographicArea')}
                    helperText={t('Please enter geographic area')}
                    placeholder={t('Enter geographic area')}
                    value={value}
                    withOutLabel
                    onChange={onChange}
                    error={form.errors.website?.message}
                  />
                )}
              />
            </CFormGroup>
          )}
          {allFields && (
            <CFormGroup className={row}>
              <Controller
                name='arianna'
                control={form.control}
                defaultValue=''
                render={({ onChange, value }) => (
                  <Input
                    id={t('arianna')}
                    helperText={t('Please enter arianna')}
                    placeholder={t('Enter arianna')}
                    value={value}
                    withOutLabel
                    onChange={onChange}
                    error={form.errors.website?.message}
                  />
                )}
              />
              <CCol xs='6' sm='6' />
            </CFormGroup>
          )}
        </CForm>
      </CCardBody>
    </CCard>
  )
})

export { BookSellerForm }
