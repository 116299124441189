import Axios from 'libs/axios';
import Storage from 'libs/storage';
import { JWT_TOKEN } from 'config';

const Logout = async () => {
  Storage.deleteKey(JWT_TOKEN);
};

const Login = async (data) => {
  const result = await Axios.base().post('/auth/login', data);
  Storage.setItem(JWT_TOKEN, result.data.token);
  return result;
};

const ForgotPassword = async (data) => {
  const result = await Axios.base().post('/auth/forgot-password', data);
  return result;
};

const ResetPassword = async (data) => {
  const result = await Axios.base().post('/auth/reset-password', data);
  return result;
};

const ChangePassword = async (data) => {
  const result = await Axios.authenticated().patch(
    '/users/me/change-password',
    data
  );
  return result;
};

const UpdateMe = async (data) => {
  const result = await Axios.authenticated().patch('/users/me', data);
  return result;
};

const CreateUser = async (data) => {
  const result = await Axios.authenticated().post('/users', data);
  return result;
};

const EditUserById = async (data) => {
  const userId = data.userId;
  delete data.userId;

  const result = await Axios.authenticated().patch(`/users/${userId}`, data);
  return result;
};

const CreateBookSeller = async (data) => {
  const result = await Axios.authenticated().post('/booksellers', data);
  return result;
};

const EditBookSellerById = async (data) => {
  const bookSellerId = data.bookSellerId;
  delete data.bookSellerId;

  const result = await Axios.authenticated().patch(
    `/booksellers/${bookSellerId}`,
    data
  );
  return result;
};

const UploadBooksellersCSV = async (data) => {
  const result = await Axios.authenticated().post('/booksellers/upload', data);
  return result;
};

const UploadRolloutsBookCSV = async (data) => {
  const result = await Axios.authenticated().post(
    `/rollout-book/upload/${data.rolloutsId}`,
    data.form
  );
  return result;
};

const CreateRollout = async (data) => {
  const result = await Axios.authenticated().post('/rollouts', data);
  return result;
};

const EditRolloutsById = async (data) => {
  const rolloutsId = data.rolloutsId;
  delete data.rolloutsId;

  const result = await Axios.authenticated().patch(
    `/rollouts/${rolloutsId}`,
    data
  );
  return result;
};

const UploadAgentObjectiveCSV = async (data) => {
  const result = await Axios.authenticated().post(
    '/agent-objective/upload',
    data
  );
  return result;
};

const UploadAgentsBooksellersPublishersCSV = async (data) => {
  const result = await Axios.authenticated().post(
    '/agents-booksellers-publishers/upload/',
    data
  );
  return result;
};

const AgentPerfomanceCreate = async (data) => {
  const result = await Axios.authenticated().post('/agent-perfomance', data);
  return result;
};

const UpdateDiscount = async (data) => {
  const result = await Axios.authenticated().patch('/users/me/discounts', data);
  return result;
};

export {
  Logout,
  Login,
  ForgotPassword,
  ResetPassword,
  ChangePassword,
  UpdateMe,
  CreateUser,
  EditUserById,
  CreateBookSeller,
  EditBookSellerById,
  UploadBooksellersCSV,
  UploadRolloutsBookCSV,
  UploadAgentObjectiveCSV,
  CreateRollout,
  EditRolloutsById,
  UploadAgentsBooksellersPublishersCSV,
  AgentPerfomanceCreate,
  UpdateDiscount,
};
