import axios from 'axios'
import Storage from './storage'
import miniToastr from './minitoastr'
import {API_URL, JWT_TOKEN, MOBILE_API_URL} from 'config'

const formatErrors = (error) => {
  let msg = '<ul>'
  msg += `<li>Message: ${error.details || error.message}</li>`
  msg += `<li>Status: ${error.status}</li>`
  return msg
}

const handleError = (error) => {
  switch (error.response.status) {
    case 404:
      miniToastr.warn(formatErrors(error.response.data), 'Request failed')
      break
    case 401:
      const data = error.response.data
      if (data.details) {
        miniToastr.error(formatErrors(error.response.data), 'Unauthorized')
      }
      break
    case 403:
      miniToastr.error(formatErrors(error.response.data), 'Forbidden')
      break
    case 422:
      miniToastr.warn(formatErrors(error.response.data), 'Unprocessable Entity')
      break
    case 500:
      miniToastr.error(formatErrors(error.response.data), 'Internal Server Error')
      break
    default:
      miniToastr.error(formatErrors(error.response.data), 'Unknown Error')
  }
}

class Axios {
  constructor () {
    this.instance = axios.create({
      baseURL: API_URL,
      timeout: 4 * 60 * 1000,
      headers: {
        'Content-Type': 'application/json'
      }
    })

    this.instance.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      if (error.response) {
        handleError(error)
      } else if (error.request) {
      } else {
      }
      return Promise.reject(error)
    })
  }

  base () {
    return this.instance
  }

  authenticated () {
    this.instance.defaults.headers.common.Authorization = `Bearer ${Storage.getItem(JWT_TOKEN)}`
    return this.instance
  }
}


class MobileAxiosClass extends Axios {
  constructor () {
    super();
    this.instance = axios.create({
      baseURL: MOBILE_API_URL,
      timeout: 4 * 60 * 1000,
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }
}

export const MobileAxios = new MobileAxiosClass()

export default new Axios()
