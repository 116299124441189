export const extractFileName = (contentDispositionValue) => {
  let filename = '';

  if (
    contentDispositionValue &&
    contentDispositionValue.includes('attachment')
  ) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDispositionValue);

    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }

  return filename;
};
