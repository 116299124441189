import React from 'react'
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg
} from '@coreui/react'
import CIcon from '@coreui/icons-react'

import { Logout } from 'api/mutations'
import { Link } from 'react-router-dom'
import { ROUTES } from 'routes'

const TheHeaderDropdown = () => {
  const logout = () => {
    Logout()
    window.location.href = ROUTES.HOME
  }

  return (
    <>
      <CDropdown
        inNav
        className='c-header-nav-items mx-2'
        direction='down'
      >
        <CDropdownToggle className='c-header-nav-link' caret={false}>
          <div className='c-avatar'>
            <CImg
              src='avatars/anonimous.png'
              className='c-avatar-img'
              alt='admin@bootstrapmaster.com'
            />
          </div>
        </CDropdownToggle>
        <CDropdownMenu className='pt-0' placement='bottom-end'>
          <CDropdownItem>
            <Link to={ROUTES.PROFILE} id='user-edit'>
              <CIcon name='cil-user' className='mfe-2' />
              Profile
            </Link>
          </CDropdownItem>
          <CDropdownItem divider />
          <CDropdownItem>
            <a href='#' onClick={logout}>
              <CIcon name='cil-lock-locked' className='mfe-2' />
              Logout
            </a>
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
      {/* {showForm && <ProfilesForm />} */}
    </>
  )
}

export default TheHeaderDropdown
