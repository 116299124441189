import withCurrentUser from 'hoc/withCurrentUser'
import TheAside from './TheAside'
import TheFooter from './TheFooter'
import TheHeader from './TheHeader'
import TheHeaderDropdown from './TheHeaderDropdown'
import TheHeaderDropdownMssg from './TheHeaderDropdownMssg'
import TheHeaderDropdownNotif from './TheHeaderDropdownNotif'
import TheHeaderDropdownTasks from './TheHeaderDropdownTasks'
import TheLayout from './TheLayout'
import TheSidebar from './TheSidebar'

const Sidebar = withCurrentUser(TheSidebar)
export {
  TheAside,
  TheFooter,
  TheHeader,
  TheHeaderDropdown,
  TheHeaderDropdownMssg,
  TheHeaderDropdownNotif,
  TheHeaderDropdownTasks,
  TheLayout,
  Sidebar
}
