const formatMoney = (locale, currency, number) => {
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: currency,
  }).format(number);
};

const capitalizeFirstLetter = (string) => {
  const withSpaces = string.replace(/([a-z])([A-Z])/g, "$1 $2");
  return withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1);
};

const filterEmptyKeys = (data) => {
  for (const key in data) {
    if (data[key] === "") {
      delete data[key];
    }
  }
  return data;
};

const renderHeaders = (arr) => {
  return (
    <>
      {arr.map((item, idx) => {
        return (
          <p key={idx} style={{ margin: 0 }}>
            {item}
          </p>
        );
      })}
    </>
  );
};

const fixedNum = (num) => {
  const splitNum = num.toString().split(".");
  if (splitNum[1] && splitNum[1].length >= 3) {
    return num.toFixed(3);
  } else if (splitNum[1] && splitNum[1].length < 3) {
    return num.toFixed(2);
  } else if (!splitNum[1]) {
    return num;
  }
};

const convertDate = (str) => {
  const date = new Date(str);
  const res = date.toLocaleDateString();
  return res;
};

const urlWithQuery = (url, page, size, queries) => {
  //console.log("URLWITHQUERY -->", url, page, size, queries);
  if (page) {
    url += `?page=${page || 1}`;
  }
  // url += `?page=${page || 1}`
  if (size) {
    url += `&size=${size}`;
  }

  if (queries !== undefined) {
    let q = Object.entries(queries)
      .filter(([_, v]) => !!v)
      .map(([k, v]) => `${k}=${v}`);

    //console.log("Q -->", q);
    //console.log("Q JOIN-->", q.join("&"));
    url += `&${q.join("&")}`;
  }
  return url;
};

const queriesToArray = (data) => {
  const queries = [];
  for (const key in data) {
    const query = `&${key}=${data[key]}`;
    queries.push(query);
  }
  return queries;
};

const checkPermisison = (required, permissions) => {
  let access = false;

  required.forEach((key) => {
    if (permissions[key]) {
      access = true;
    }
  });
  return access;
};

const usersPermissions = {
  rolloutsManagement: false,
  agentsManagement: false,
  weightsManagement: false,
};

const agentsPermissions = {
  ordersManagement: false,
  importRunsManagement: false,
};

const getFromArrayByKey = (arr, key, value) =>
  arr.find((obj) => obj[key] === value);

export {
  formatMoney,
  capitalizeFirstLetter,
  filterEmptyKeys,
  urlWithQuery,
  queriesToArray,
  checkPermisison,
  convertDate,
  fixedNum,
  renderHeaders,
  usersPermissions,
  agentsPermissions,
  getFromArrayByKey,
};
