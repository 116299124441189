import { sygnet } from "./sygnet";
import { logo } from "./logo";
import { logoNegative } from "./logo-negative";
import pde from "./pde-icona.png";
import pdeDark from "./logo_pde.svg";

export const icons = Object.assign(
  {},
  {
    sygnet,
    logo,
    logoNegative,
  }
);

export const images = {
  LOGO: pde,
  LOGO_DARK: pdeDark,
};
