import ROLES from 'libs/roles';
import { ROUTES } from 'routes';
import i18next from 'libs/i18n';

const _nav = [
  {
    _tag: 'CSidebarNavItem',
    name: 'Dashboard',
    to: ROUTES.HOME,
    icon: 'cil-speedometer',
    badge: {
      color: 'info',
      // text: 'NEW',
    },
    allowed: [
      ROLES.ADMIN,
      ROLES.USER.ROLE,
      ROLES.AGENT.ROLE,
      ROLES.KEY_ACCOUNT,
    ],
  },
  {
    _tag: 'CSidebarNavItem',
    name: i18next.t('Users'),
    to: ROUTES.USERS,
    icon: 'cil-contact',
    badge: {
      color: 'info',
      // text: 'NEW',
    },
    allowed: [ROLES.ADMIN],
  },
  {
    _tag: 'CSidebarNavItem',
    name: i18next.t('Booksellers'),
    to: ROUTES.BOOKSELLERS,
    icon: 'cil-book',
    badge: {
      color: 'info',
      // text: 'NEW',
    },
    allowed: [ROLES.ADMIN, ROLES.USER.AGENTS_MANAGEMENT],
  },
  {
    _tag: 'CSidebarNavItem',
    name: i18next.t('Rollouts'),
    to: ROUTES.ROLLOUTS,
    icon: 'cil-list',
    badge: {
      color: 'info',
      // text: 'NEW',
    },
    allowed: [ROLES.ADMIN, ROLES.AGENT.ROLE, ROLES.KEY_ACCOUNT],
  },
  {
    _tag: 'CSidebarNavItem',
    name: i18next.t('Agents Booksellers Publishers'),
    to: ROUTES.AGENTS_BOOKSELLERS_PUBLISHERS,
    icon: 'cil-briefcase',
    badge: {
      color: 'info',
      // text: 'NEW',
    },
    allowed: [ROLES.ADMIN],
  },
  {
    _tag: 'CSidebarNavItem',
    name: i18next.t('Agents Objectives'),
    to: ROUTES.AGENT_OBJECTIVES,
    icon: 'cil-scrubber',
    badge: {
      color: 'info',
      // text: 'NEW',
    },
    allowed: [ROLES.ADMIN, ROLES.AGENT.ROLE, ROLES.KEY_ACCOUNT],
  },
  {
    _tag: 'CSidebarNavItem',
    name: i18next.t('Discounts'),
    to: ROUTES.OVER_DISCOUNTS,
    icon: 'cil-scrubber',
    badge: {
      color: 'info',
      // text: 'NEW',
    },
    allowed: [ROLES.ADMIN, ROLES.AGENT.ROLE, ROLES.KEY_ACCOUNT],
  } /* ,
  {
    _tag: 'CSidebarNavItem',
    name: 'Agents Perfomances',
    to: '/agents-perfomances-all',
    icon: 'cil-library-building',
    badge: {
      color: 'info'
      // text: 'NEW',
    },
    allowed: [ROLES.ADMIN]
  } */,
];

export default _nav;
