import { CPagination } from '@coreui/react'
import React from 'react'

const Paginator = React.memo((props) => {
  const {
    totalPages,
    currentPage,
    setActivePage
  } = props

  return (
    <CPagination
      activePage={currentPage}
      pages={totalPages}
      onActivePageChange={(i) => setActivePage(i)}
    />
  )
})

export { Paginator }
