import Axios from 'libs/axios';
import { urlWithQuery } from 'libs/utils';

const Me = async (key) => {
  const result = await Axios.authenticated().get('/users/me');
  return result;
};

const Users = async (page, size, queries) => {
  let url = '/users/';
  url = urlWithQuery(url, page, size, queries);

  const result = await Axios.authenticated().get(url);
  return result;
};

const BookSellers = async (page, size, queries) => {
  let url = '/booksellers/';
  url = urlWithQuery(url, page, size, queries);

  const result = await Axios.authenticated().get(url);
  return result;
};

const BookSellersDatasFilter = async (query) => {
  const url = `/booksellers/data/filter/${query}`;

  const result = await Axios.authenticated().get(url);
  return result;
};

const Rollouts = async (page, size, queries) => {
  let url = '/rollouts/';
  url = urlWithQuery(url, page, size, queries);

  const result = await Axios.authenticated().get(url);
  return result;
};

const RolloutsToSelect = async () => {
  const url = '/rollouts/data/to-select';
  const result = await Axios.authenticated().get(url);
  return result;
};

const RolloutsBooksByRolloutId = async (rolloutId, page, size, queries) => {
  let url = `/rollout-book/${rolloutId}`;
  url = urlWithQuery(url, page, size, queries);
  const result = await Axios.authenticated().get(url);
  return result;
};

const PublishersFromRolloutBooks = async (rolloutId) => {
  const url = `/rollout-book/publishers/${rolloutId}`;
  const result = await Axios.authenticated().get(url);
  return result;
};

const RolloutBooksToSelect = async (rolloutId) => {
  const url = `/rollout-book/data/to-select/${rolloutId}`;
  const result = await Axios.authenticated().get(url);
  return result;
};

const RolloutsBooks = async (page, size, queries) => {
  let url = '/rollout-book/';
  url = urlWithQuery(url, page, size, queries);

  url += '&agentPerfomances=true';

  const result = await Axios.authenticated().get(url);
  return result;
};

const AgentObjectivesAll = async (page, size, queries) => {
  let url = '/agent-objective/';
  url = urlWithQuery(url, page, size, queries);

  const result = await Axios.authenticated().get(url);
  return result;
};

const AllAgentObjectivesByAgentId = async (agentId) => {
  const url = `/agent-objective/?agentId=${agentId}`;
  const result = await Axios.authenticated().get(url);
  return result;
};

const AgentObjectivesFilterData = async (query) => {
  const url = `/agent-objective/byrgx/data/filter/${query}`;
  const result = await Axios.authenticated().get(url);
  return result;
};

const AgentObjectivesByAgentIdVsPublisherCode = async (
  agentId,
  publisherCode,
) => {
  const url = `/agent-objective/${agentId}/${publisherCode}`;
  const result = await Axios.authenticated().get(url);
  return result;
};

const AgentBooksellersPublishersByAgentId = async (
  agentId,
  distincValue,
  page,
  size,
  queries,
) => {
  let url = `/agents-booksellers-publishers/${agentId}/${distincValue}`;
  url = urlWithQuery(url, page, size, queries);

  const result = await Axios.authenticated().get(url);
  return result;
};

const AgentBooksellerPublisherAll = async (page, size, queries) => {
  let url = '/agents-booksellers-publishers';
  url = urlWithQuery(url, page, size, queries);

  const result = await Axios.authenticated().get(url);
  return result;
};

const AgentBooksellerOnlyPublishers = async (page, size, queries) => {
  let url = '/agents-booksellers-publishers/only-publishers';
  url = urlWithQuery(url, page, size, queries);

  const result = await Axios.authenticated().get(url);
  return result;
};

const AgentBooksellersPublishersFilterData = async (agentId, query) => {
  const url = `/agents-booksellers-publishers/data/filter/${agentId}/${query}`;
  const result = await Axios.authenticated().get(url);
  return result;
};

const AgentPerfomanceGetOneByIds = async (params) => {
  const { rolloutBookId, rolloutId, agentId, booksellerId } = params;
  const url = `/agent-perfomance/${rolloutId}/${agentId}/${booksellerId}/${rolloutBookId}`;
  const result = await Axios.authenticated().get(url);
  return result;
};

const AgentsPerfomancesByRolloutId = async (rolloutId, page, size, queries) => {
  let url = `/agent-perfomance/${rolloutId}`;
  url = urlWithQuery(url, page, size, queries);

  const result = await Axios.authenticated().get(url);
  return result;
};

const AgentPerfomanceFilterData = async (rolloutId, query) => {
  const url = `/agent-perfomance/data/filter/${rolloutId}/${query}`;
  const result = await Axios.authenticated().get(url);
  return result;
};

const SumOfReservedCopiesById = async (query) => {
  const url = `/agent-perfomance/reserved-copies/sum/${query}`;
  const result = await Axios.authenticated().get(url);
  return result;
};

const SumOfRolloutBookObjectiveByRolloutiId = async (rolloutId, query) => {
  let url = `/rollout-book/objective-sum/${rolloutId}`;
  if (query) {
    url = url + `/${query}`;
  }
  const result = await Axios.authenticated().get(url);
  return result;
};
const SumOfRolloutBookObjectiveByRolloutId = async (rolloutId, query) => {
  let url = `/rollout-book/objective-sum-pub-book/${rolloutId}`;
  if (query) {
    url = url + `/${query}`;
  }
  const result = await Axios.authenticated().get(url);
  return result;
};
const PerformanceByRolloutPublisherAndBook = async (rolloutId, query) => {
  let url = `/agent-perfomance/performance/${rolloutId}`;
  if (query) {
    url = url + `/${query}`;
  }
  const result = await Axios.authenticated().get(url);
  return result;
};

const ArrayOfIdsFromAgentPerfomance = async (agentId, tag) => {
  const url = `/agent-perfomance/ids/${agentId}/${tag}`;
  const result = await Axios.authenticated().get(url);
  return result;
};

const ObjectiveSumByArrayOfIds = async (array, tag) => {
  const url = `/rollout-book/objective-sum/by-ids/${tag}`;
  const result = await Axios.authenticated().post(url, array);
  return result;
};

const RolloutDownload = async ({ rolloutId, agentId }) => {
  const url = `/rollouts/${agentId}/${rolloutId}/download`;
  const result = await Axios.authenticated().get(url, {
    responseType: 'blob',
  });
  return result;
};

export {
  Me,
  Users,
  BookSellers,
  BookSellersDatasFilter,
  Rollouts,
  RolloutsBooksByRolloutId,
  AgentObjectivesAll,
  AgentObjectivesFilterData,
  AllAgentObjectivesByAgentId,
  AgentBooksellersPublishersByAgentId,
  AgentBooksellersPublishersFilterData,
  RolloutsBooks,
  AgentPerfomanceGetOneByIds,
  AgentBooksellerPublisherAll,
  AgentsPerfomancesByRolloutId,
  AgentPerfomanceFilterData,
  AgentObjectivesByAgentIdVsPublisherCode,
  RolloutsToSelect,
  SumOfReservedCopiesById,
  SumOfRolloutBookObjectiveByRolloutiId,
  PublishersFromRolloutBooks,
  RolloutBooksToSelect,
  ArrayOfIdsFromAgentPerfomance,
  ObjectiveSumByArrayOfIds,
  AgentBooksellerOnlyPublishers,
  SumOfRolloutBookObjectiveByRolloutId,
  PerformanceByRolloutPublisherAndBook,
  RolloutDownload,
};
