import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
  CToggler
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  TheHeaderDropdown
} from './index'
import { useTranslation } from 'react-i18next'
import { images } from '../assets/icons'

const TheHeader = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const darkMode = useSelector(state => state.darkMode)
  const sidebarShow = useSelector(state => state.sidebarShow)

  const toggleSidebar = () => {
    const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive'
    dispatch({ type: 'set', sidebarShow: val })
  }

  const toggleSidebarMobile = () => {
    const val = [false, 'responsive'].includes(sidebarShow) ? true : 'responsive'
    dispatch({ type: 'set', sidebarShow: val })
  }

  return (
    <CHeader withSubheader>
      <CToggler
        inHeader
        className='ml-md-3 d-lg-none'
        onClick={toggleSidebarMobile}
      />
      <CToggler
        inHeader
        className='ml-3 d-md-down-none'
        onClick={toggleSidebar}
      />
      <CHeaderBrand className='mx-auto d-lg-none' to='/'>
        <img className='pt-2 pb-2' src={images.LOGO_DARK} alt='PDE logo' style={{ height: '60px' }} />
      </CHeaderBrand>

      <CHeaderNav className='d-md-down-none mr-auto'>
        <CHeaderNavItem className='px-3'>
          <CHeaderNavLink to='/'>
            {t('Dashboard')}
          </CHeaderNavLink>
        </CHeaderNavItem>
      </CHeaderNav>

      <CHeaderNav className='px-3'>
        <CToggler
          inHeader
          className='ml-3 d-md-down-none c-d-legacy-none'
          onClick={() => dispatch({ type: 'set', darkMode: !darkMode })}
          title='Toggle Light/Dark Mode'
        >
          <CIcon name='cil-moon' className='c-d-dark-none' alt='CoreUI Icons Moon' />
          <CIcon name='cil-sun' className='c-d-default-none' alt='CoreUI Icons Sun' />
        </CToggler>
        <TheHeaderDropdown />
      </CHeaderNav>

    </CHeader>
  )
}

export default TheHeader
