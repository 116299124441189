const { CCol, CButton } = require('@coreui/react')

const BtnWrapped = (props) => (
  <CCol className='mb-xl-2 col-sm-2 col-md-2'>
    {props.children}
  </CCol>
)

const Button = ({ title, icon, children, wrapped, ...buttonProps }) => {
  const button = (
    <CButton block {...buttonProps}>
      {title} {icon} {children}
    </CButton>
  )

  if (wrapped) {
    return (
      <BtnWrapped>
        {button}
      </BtnWrapped>
    )
  }
  return button
}

export { Button }
