// eslint-disable-next-line import/no-anonymous-default-export
export default {
  'MenuClick24 - Piano Annuale': 'annual plan',
  'Paghi un mese alla volta, disdici quando vuoi': 'siper',
  success: 'Success',
  'sucessfull updated': 'Your data updated successfully',
  'go home': 'Go to home',
  cancel: 'Cancel',
  'Change profile info': 'Change profile info',
  name: 'name',
  arianna: 'arianna',
  geographicArea: 'geographicArea',
  surname: 'surname',
  email: 'email',
  Active: 'Active',
  active: 'active',
  inactive: 'inactive',
  Status: 'Status',
  discounts: 'Discounts',
  rolloutsManagement: 'Rollouts management',
  agentsManagement: 'Agents management',
  weightsManagement: 'Weights management',
  ordersManagement: 'Orders management',
  importRunsManagement: 'Import runs management',
  language: 'language',
  Submit: 'Submit',
  Create: 'Create',
  'Edit bookseller': 'Edit bookseller',
  'New bookseller': 'New bookseller',
  Booksellers: 'Booksellers',
  companyName: 'companyName',
  city: 'city',
  code: 'code',
  Upload: 'Upload',
  'Choose file': 'Choose file',
  'Create rollout': 'Create rollout',
  'Upload file': 'Upload file',
  'Edit Rollout': 'Edit Rollout',
  startDate: 'Start date',
  endDate: 'End date',
  'Agents code': 'Agents code',
  'Rollout books': 'Rollout books',
  ean: 'ean',
  title: 'title',
  format: 'format',
  price: 'price',
  author: 'author',
  publisherCode: 'publisherCode',
  publisherCompanyName: 'publisherCompanyName',
  'Agent objectives': 'Agent objectives',
  'Agent name': 'Agent name',
  'Agent surname': 'Agent surname',
  booksellerCompanyName: 'booksellerCompanyName',
  booksellerCode: 'booksellerCode',
  'Agents Booksellers Publishers': 'Agents Booksellers Publishers',
  'Agents Booksellers Publishers data changed successfully':
    'Agents Booksellers Publishers data changed successfully',
  'Handle bookseller': 'Handle bookseller',
  'Booksellers company name': 'Booksellers company name',
  'Booksellers code': 'Booksellers code',
  'Agent perfomance': 'Agent perfomance',
  Rollouts: 'Rollouts',
  'Agent perfomances': 'Agent perfomances',
  'National objective': 'National objective',
  Achievement: 'Achievement',
  All: 'All',

  'Please enter your name': 'Please enter your name',
  'Please enter your surname': 'Please enter your surname',
  'Please enter your email': 'Please enter your email',
  'Please enter name': 'Please enter name',
  'Please enter surname': 'Please enter surname',
  'Please enter email': 'Please enter email',
  'Please enter company name': 'Please enter company name',
  'Please enter code': 'Please enter code',
  'Please enter city': 'Please enter city',
  'Please enter province': 'Please enter province',
  'Please enter address': 'Please enter address',
  'Please enter zip code': 'Please enter zip code',
  'Please enter contry': 'Please enter contry',
  'Please enter telephone': 'Please enter telephone',
  'Please enter website': 'Please enter website',
  'Please enter facebook': 'Please enter facebook',
  'Please enter chanel': 'Please enter chanel',
  'Please enter clients group': 'Please enter clients group',
  'Please enter VAT number': 'Please enter VAT number',
  'Please enter goods destination': 'Please enter goods destination',
  'Please enter accounts type': 'Please enter accounts type',
  'Please enter region': 'Please enter region',
  'Please enter geographic area': 'Please enter geographic area',
  'Please enter arianna': 'Please enter arianna',

  'Change password': 'Change password',
  'Current password': 'Current password',
  'Please enter your current password': 'Please enter your current password',
  'new password': 'new password',
  'Please enter your new password': 'Please enter your new password',
  'confirm password': 'confirm password',
  'Please confirm your new password': 'Please confirm your new password',
  'Please enter enter date': 'Please enter enter date',
  'Create user': 'Create user',
  'Max limit achieved': 'Max limit achieved',
  'sucessfull user created': 'User created successfully',
  'Booksellers data changed successfully':
    'Booksellers data changed successfully',
  'Rollouts book data changed successfully':
    'Rollouts book data changed successfully',
  'Rollout changed successfully': 'Rollout changed successfully',
  'Agent objectives data changed successfully':
    'Agent objectives data changed successfully',
  'Agent perfomance created successfully':
    'Agent perfomance created successfully',

  Close: 'Close',
  Update: 'Update',

  'New user': 'New user',
  'Enter name': 'Enter name',
  'Enter surname': 'Enter surname',
  'Enter email': 'Enter email',
  'Enter company name': 'Enter company name',
  'Enter facebook': 'Enter facebook',
  'Enter website': 'Enter website',
  'Enter telephone': 'Enter telephone',
  'Enter contry': 'Enter contry',
  'Enter zip code': 'Enter zip code',
  'Enter address': 'Enter address',
  'Enter province': 'Enter province',
  'Enter city': 'Enter city',
  'Enter code': 'Enter code',
  'Enter chanel': 'Enter chanel',
  'Enter clients group': 'Enter clients group',
  'Enter VAT number': 'Enter VAT number',
  'Enter clients goods destination': 'Enter clients goods destination',
  'Enter accounts type': 'Enter accounts type',
  'Enter region': 'Enter region',
  'Enter geographic area': 'Enter geographic area',
  'Enter arianna': 'Enter arianna',
  role: 'role',

  'Name is a requierd field': 'Name is a requierd field',
  'Surname is a requierd field': 'Surname is a requierd field',
  'Email is a requierd field': 'Email is a requierd field',
  'Must be a valid email': 'Must be a valid email',
  'Company name is a required field': 'Company name is a required field',
  'Code is a required field': 'Code is a required field',
  'Current password is a required field':
    'Current password is a required field',
  'New password is a required field': 'New password is a required field',
  'Confirm password is a required field':
    'Confirm password is a required field',
  'Confirm password have to match with new password':
    'Confirm password have to match with new password',
  'Start date is a required field': 'Start date is a required field',
  'End date is a required field': 'End date is a required field',

  Filter: 'Filter',
  'User data updated successfully': 'User data updated successfully',
  Edit: 'Edit',

  Company: 'Company',
  Code: 'Code',
  City: 'City',
  province: 'province',
  address: 'address',
  'Zip code': 'Zip code',
  Country: 'Country',
  telephone: 'telephone',
  website: 'website',
  facebook: 'facebook',
  absorptionIndex: 'Absorption Index',
};
